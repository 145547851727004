const dev = {
	DEPLOYED_NTW_NAME: 'Goerli',
	DEPLOYED_CHAIN_ID: 5,
	INFURA_ID: 'a5e79e6ee9a14236b385e47849805596',
	FORTMATIC_KEY: 'pk_test_DD2BBA8AAA1D4FED',
	RPC_URL: 'https://goerli.infura.io/v3/cae2aed2f50a4087af91b000cf05be4c',

	BASE_CID: 'https://ec-serverapp-staging.herokuapp.com/card',
	API_URL: 'https://pluto-v2-metadata-staging.herokuapp.com/',
	//API_URL: 'http://localhost:5000/',

	ETHERSCAN_URL: 'https://rinkeby.etherscan.io/',
	POLYGON_EXPLORER_URL: "https://mumbai.polygonscan.com/",

	ETH_CHAIN_ID: 5,
	POLYGON_CHAIN_ID: 80001,
	GOERLI_RPC_URL: 'https://goerli.infura.io/v3/cae2aed2f50a4087af91b000cf05be4c',
};

const prod = {
	DEPLOYED_NTW_NAME: 'mainnet',
	DEPLOYED_CHAIN_ID: 1,
	INFURA_ID: 'a5e79e6ee9a14236b385e47849805596',
	FORTMATIC_KEY: 'pk_live_FBFF1F05F2879F29',
	RPC_URL: 'https://mainnet.infura.io/v3/0a0bbd3ce4ea4be5ad706514cf2cd8cc',

	BASE_CID: 'https://heroku.ether.cards/card',
	API_URL: 'https://pluto-v2-metadata-server.ether.cards/',
	// API_URL: 'http://localhost:5000/',

	ETHERSCAN_URL: 'https://etherscan.io/',
	POLYGON_EXPLORER_URL: "https://www.polygonscan.com/",

	ETH_CHAIN_ID: 1,
	POLYGON_CHAIN_ID: 137,
};

const common = {
	APP_BASE_URL: 'https://YOUR_APP.ether.cards',
	LAYERS_BASE_URL: 'https://ether-cards.mypinata.cloud/ipfs/Qmcm7BjsmhwWVA611EZSGkxcqt3JmsbF9m37kPNhDLoy4o',
	FORGE_BASE_URL: "https://ether-cards.mypinata.cloud/ipfs/QmfWB2UjH5JTCidMw6dPTwcVabh2Y88dfAXbAMUNtJVinR",
	POLYGON_RPC: 'https://polygon-mainnet.infura.io/v3/0a0bbd3ce4ea4be5ad706514cf2cd8cc',
	GOERLI_RPC: 'https://goerli.infura.io/v3/cae2aed2f50a4087af91b000cf05be4c',
};

// if use npm/yarn start,  NODE_ENV = "development"
// if use npm/yarn build,  NODE_ENV = "production"
// let envConfig = dev// process.env.NODE_ENV === "development" ? dev : prod
const config = { ...prod, ...common };

export default config;
