import { Box, Typography, Grid, Button, Stack, Container } from "@mui/material";

const sx = {
  stepper: (enable) => ({
    my: {
      xs: 2,
      md: 1,
      lg: 0,
    },
    opacity: enable ? 1 : 0.5,
    transition: "all .15s",
    "&:hover": {
      // cursor: "pointer",
      // opacity: 1,
    },
  }),
  stepperId: {
    position: "relative",
    height: "50px",
    width: "50px",
    mx: "15px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    fontFamily: "bold-poppins",
    zIndex: 0,
    backdropFilter: "blur(2px)",
    "&:before": {
      position: "absolute",
      content: "''",
      height: "50px",
      width: "50px",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background:
        "transparent linear-gradient(105deg, #00000080 0%, #27DFC733 100%) 0% 0% no-repeat padding-box",
      border: "1px solid #27DFC7",
      borderRadius: "6px",
      transform: "rotate(45deg)",
      zIndex: 0,
    },
  },
  stepperText: {
    fontSize: "14px",
    color: "#fff",
    my: 3,
    width: "100px",
    alignSelf: "center",
    fontFamily: "poppins",
  },
};
const Stepper = ({ id, step, text, onClick }) => {
  return (
    <Box
      sx={sx.stepper(id == step)}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <Stack direction="row" spacing="2">
        <Box sx={sx.stepperId}>{id}</Box>
        <Box sx={sx.stepperText}>{text}</Box>
      </Stack>
    </Box>
  );
};

export default Stepper;
