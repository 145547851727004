import { useMemo, useState, useEffect, useCallback } from "react";
import useWeb3Ctx from "./useWeb3Ctx";

import { usePlutoV2ForgeContractPolygon } from "./useContract";

const useForgeRewards = () => {
    const { address } = useWeb3Ctx()
    const contract = usePlutoV2ForgeContractPolygon();
    const [rewardArray, setRewardArray] = useState([]);
    const [hasReward, setHasReward] = useState(false);

    const fetchInfo = useCallback(() => {
        if (contract) {
            (async () => {
                console.log('fetching Info')
                const rewards = await contract.getAllRewardsForAddress(address);
                let reward = false
                let rewardIds = [];
                rewards.forEach((amount, index) => {
                    const rewardId = index + 1;
                    if (amount > 0 && rewardId != 5) {
                        reward = true
                        rewardIds.push({
                            id: rewardId,
                            amount: amount
                        })
                    }
                })
                setHasReward(reward);
                setRewardArray(rewardIds)
            })();
        }
    }, [contract, address]);

    useEffect(() => {
        if (address) {
            fetchInfo();
        }
    }, [address])

    return useMemo(() => {
        // console.log(rewardArray, hasReward)
        return { rewardArray, hasReward, fetchInfo }
    }, [rewardArray, hasReward]);
}

export default useForgeRewards;