import { Box, Typography, Grid } from "@mui/material";
import ethIcon from "../../../assets/images/chains/eth-chain.png"
import polygonIcon from "../../../assets/images/chains/polygon-chain.png"
const sx = {
  cardsContainer: {
    minHeight: "300px",
    width: "100%",
    background:
      "transparent linear-gradient(105deg, #00000080 0%, #27DFC733 100%) 0% 0% no-repeat padding-box",
    border: "1px solid #27DFC7",
    borderRadius: "6px",
    p: 2,
    my: 2,
  },
  cardsHeader: {
    textAlign: "center",
    color: "#fff",
    fontFamily: "bold-poppins",
    mb: 2,
  },
  nftContainer: (selected) => ({
    backgroundColor: "transparent",
    width: "100%",
    paddingTop: "117%",
    position: "relative",
    overflow: "hidden",
    // transition: "all .3s",
    perspective: "1px",
    mb: 1,
    borderRadius: "10px",
    outline: selected ? "solid 5px #fff" : "unset",
    "&:hover": {
      cursor: "pointer",
      // transform: selected ? "scale(1)" : "scale(1.05)",
      // borderColor: "transparent",
    },
  }),
  nftImg: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  nftText: {
    color: "#fff",
    textAlign: "center",
    fontWeight: 600,
    fontSize: "14px",
  },
  headerGradiant: {
    backgroundImage: "linear-gradient(180deg,#27DFC7,#fff)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  gridContainer: {
    my: 3,
    mx: "auto",
    maxWidth: "80%",
    justifyContent: "center",
  },
  chainInfoHolder:{
    position:"absolute",
    top:10,
    left:6,
    zIndex:"3"
  },
  chainInner:{
    height:"32px",
    padding:"6px",
    display:"flex",
    alignItems:"center"
  },
  chainInfoBG:{
    backgroundColor:"#fff",
    borderRadius:"50px",
    "& img": {
      maxHeight:"22px",
      maxWidth: "14px",
      mx:"4px"
    },
    "& span":{
      color:"#000",
      fontSize:"10px",
      display:"none"
    },
    "&:hover": {
      "& span":{
        display:"block",
        transition:"2s all"
      }
    }
  },
  
};

const ChainInfo = ({isOnMainnet}) =>{
  return <>
  <Box sx={sx.chainInfoHolder}>
    <Box sx={sx.chainInfoBG}>
    {isOnMainnet ? 
    <Box sx={sx.chainInner}><img src={ethIcon}/><Typography variant="text" sx={sx.nftText}>
    Card is on Ethereum chain
  </Typography></Box> :
    <Box sx={sx.chainInner}>
      <img src={polygonIcon}/>
      <Typography variant="text" sx={{...sx.nftText, ...sx.chainText}}>
        Card is on Polygon chain
      </Typography>
    </Box>  
  }
  </Box>
    </Box></>
}

const CardsContainer = ({ selectedId, background, metadata, onClick }) => {
  return (
    <Box sx={sx.cardsContainer}>
      <Typography variant="h2" sx={sx.cardsHeader}>
        <Box component="span" sx={sx.headerGradiant}>
          {background}
        </Box>{" "}
        BACKGROUND{" "}
        <>
          {metadata.length > 0 && (
            <>
              {selectedId != null ? (
                <>- #{selectedId}</>
              ) : (
                <>
                  - CHOOSE{" "}
                  <Box component="span" sx={sx.headerGradiant}>
                    1
                  </Box>{" "}
                  CARD
                </>
              )}
            </>
          )}
        </>
      </Typography>

      {metadata.length == 0 && (
        <Typography sx={{ color: "#fff", textAlign: "center" }}>
          You don’t have any cards with {background.toLowerCase()} background.
        </Typography>
      )}

      <Grid container spacing={3}>
        {metadata &&
          metadata.map((metadata) => {
            return (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                lg={2}
                sx={sx.gridItem}
                key={metadata.tokenId}
                onClick={() => {
                  if (onClick) {
                    if (selectedId == metadata.tokenId) {
                      onClick(null);
                    } else {
                      onClick(metadata.tokenId);
                    }
                  }
                }}
              >
                <Box sx={sx.nftContainer(selectedId == metadata.tokenId)}>
                <ChainInfo isOnMainnet={metadata.isOnMainnet}/>
                  <Box component="img" sx={sx.nftImg} src={metadata.image} />
                </Box>
                <Typography variant="text" sx={sx.nftText}>
                  {metadata.name}
                </Typography>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

export default CardsContainer;
