import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  Typography,
  Container,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  InputLabel,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import EtherscanModal from "../../../components/EtherscanModal";
import close from "../../../assets/images/close-icon-white.svg";

import { rewardMapping } from "../constants";
import {
  NAME,
  EMAIL,
  PHONE_NUMBER,
  DISCORD_NAME,
  CONTACT_PARAMS,
  ADDRESS,
  CITY,
  STATE,
  ZIP_CODE,
  SHIPPING_PARAMS,
  WALLET_ADDRESS,
  CRUISE_TYPE,
  REWARD_TYPE,
  CRUISE,
  USDT,
  REWARD_ID,
} from "../constants/form";

import { VALID_PHONE, VALID_EMAIL, REQUIRED } from "../constants/fieldError";

const BP1 = "@media (max-width: 899px)";
const BP2 = "@media (max-width: 719px)";

const sx = {
  root: {
    height: "100%",
    minHeight: "100%",
    overflow: "auto",
    py: "44px",
    pb: "94px",
    pt: "120px",
    backgroundColor: "rgba(0,0,0,0.8)",
    transition: "all .3s",
  },
  title: {
    color: "#fff",
    fontFamily: "bold-poppins",
    fontSize: {
      xs: "26px",
      md: "34px",
      lg: "48px",
    },
    lineHeight: {
      xs: "30px",
      md: "40px",
      lg: "50px",
    },
  },
  headerGradiant: {
    backgroundImage: "linear-gradient(180deg,#27DFC7,#fff)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
  },
  textContainer: {
    position: "absolute",
    mt: "8px",
    width: "100%",
    height: "26px",
    left: 0,
  },
  closeBtn: {
    width: "24px",
    height: "24px",
    position: "fixed",
    top: "80px",
    right: "10%",
    cursor: "pointer",
    pointerEvents: "auto",
    transition: "all .3s",
    [BP1]: {
      top: "20px",
    },
  },
  url: {
    textDecoration: "none",
    color: "#19a8b4",
    fontWeight: "700",
  },
  darkOverlay: {
    zIndex: 100,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.8)",
    pointerEvents: "none",
  },
  formCard: {
    width: {
      xs: "90%",
      sm: "80%",
      md: "70%",
      lg: "60%",
    },
    background:
      "transparent linear-gradient(105deg, #00000080 0%, #27DFC733 100%) 0% 0% no-repeat padding-box",
    border: "1px solid #27DFC7",
    borderRadius: "6px",
    py: 2,
    px: {
      xs: 2,
      sm: 3,
      md: 4,
      lg: 5,
    },
    my: 2,
    mx: "auto",
    display: "flex",
    flexDirection: "column",
  },
  cardsHeader: {
    textAlign: "center",
    color: "#fff",
    fontFamily: "bold-poppins",
  },
  formControl: {
    my: 2,
    minWidth: "300px",
    mx: "auto",
    p: 0,
  },
  footerBtnContainer: {
    display: "flex",
    alignItems: "center",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    background: "#FFFFFF66 0% 0% no-repeat padding-box",
    backdropFilter: "blur(10px)",
    height: "88px",
    width: "100%",
  },
  btn: {
    minWidth: "210px",
    fontSize: "0.9rem",
    fontWeight: "600",
    mx: "auto",
    transition: "opacity 0.1s linear",
    backgroundColor: "#27DFC7",
    "&:hover": {
      backgroundColor: "#27DFC7",
      opacity: 0.9,
    },
    "&:disabled": {
      color: "#fff",
      backgroundColor: "#C7C6C6 !important",
      cursor: "not-allowed",
      opacity: 1,
    },
    zIndex: "0",
  },
};

const initialState = {
  [REWARD_ID]: null,
  [CRUISE_TYPE]: null,
  [REWARD_TYPE]: null,
  [WALLET_ADDRESS]: null,

  [NAME]: null,
  [EMAIL]: null,
  [PHONE_NUMBER]: null,
  [DISCORD_NAME]: null,

  [ADDRESS]: null,
  [CITY]: null,
  [STATE]: null,
  [ZIP_CODE]: null,
};

const initialErrorState = {
  [REWARD_TYPE]: null,
  [WALLET_ADDRESS]: null,

  [NAME]: null,
  [EMAIL]: null,
  [PHONE_NUMBER]: null,
  [DISCORD_NAME]: null,

  [ADDRESS]: null,
  [CITY]: null,
  [STATE]: null,
  [ZIP_CODE]: null,
};

const labels = {
  [NAME]: "Name",
  [EMAIL]: "Email",
  [PHONE_NUMBER]: "Phone Number",
  [DISCORD_NAME]: "Discord Name (with #'s)",
  [WALLET_ADDRESS]: "Ethereum Address",
  [ADDRESS]: "Address",
  [CITY]: "City",
  [STATE]: "State",
  [ZIP_CODE]: "Zip Code",
};

const errorTexts = {
  [NAME]: REQUIRED,
  [EMAIL]: REQUIRED,
  [PHONE_NUMBER]: REQUIRED,
  [DISCORD_NAME]: REQUIRED,

  [ADDRESS]: REQUIRED,
  [CITY]: REQUIRED,
  [STATE]: REQUIRED,
  [ZIP_CODE]: REQUIRED,

  [REWARD_TYPE]: REQUIRED,
  [WALLET_ADDRESS]: REQUIRED,
};

const CruiseForm = ({
  visible,
  setVisible,
  redeemKyc,
  redeemLoading,
  redeemTxEtherscan,
}) => {
  const [formState, setFormState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);

  useEffect(() => {
    if (!visible) {
      setFormState(initialState);
    } else {
      setErrorState(initialErrorState);
      setFormState({
        ...initialState,
        [REWARD_ID]: visible,
        [CRUISE_TYPE]: rewardMapping[visible],
      });
    }
  }, [visible]);

  useEffect(() => {
    setErrorState(initialErrorState);
  }, [formState[REWARD_TYPE]]);

  const handleChange = (fieldName) => (event) => {
    setFormState({
      ...formState,
      [fieldName]: event.target.value,
    });
  };

  const fieldErrorText = (fieldName) => {
    return errorState[fieldName]
      ? typeof errorState[fieldName] == "string"
        ? errorState[fieldName]
        : errorTexts[fieldName]
      : "";
  };

  const validateField = (fieldName, setField = true) => {
    const value = formState[fieldName];
    let notValid = value === null || value === "";

    if (!notValid) {
      if (fieldName == PHONE_NUMBER) {
        if (!isPossiblePhoneNumber(value)) {
          notValid = VALID_PHONE;
        } else {
          notValid = false;
        }
      }
      if (fieldName == EMAIL) {
        if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
          notValid = VALID_EMAIL;
        } else {
          notValid = false;
        }
      }
    }

    if (setField) {
      setErrorState({ ...errorState, [fieldName]: notValid });
    }
    return notValid;
  };

  const validateFields = () => {
    let valid = true;
    let newError = { ...errorState };

    if (formState[REWARD_TYPE] == USDT) {
      const notValid = validateField(WALLET_ADDRESS, false);
      newError[WALLET_ADDRESS] = notValid;
      if (notValid) {
        valid = false;
      }
    } else {
      CONTACT_PARAMS.forEach((fieldName) => {
        const notValid = validateField(fieldName, false);
        newError[fieldName] = notValid;
        if (notValid) {
          valid = false;
        }
      });

      SHIPPING_PARAMS.forEach((fieldName) => {
        const notValid = validateField(fieldName, false);
        newError[fieldName] = notValid;
        if (notValid) {
          valid = false;
        }
      });
    }

    setErrorState(newError);
    return valid;
  };

  const sendForm = () => {
    if (!validateFields()) return;
    if (redeemLoading) return;
    redeemKyc(formState);
  };

  return (
    <Modal
      open={visible != null || visible == true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && !redeemLoading) {
          setVisible(false);
        }
      }}
    >
      <Box sx={sx.root}>
        <Container>
          <Box sx={sx.content}>
            <Typography variant="heading1" sx={sx.title}>
              <Box component="span" sx={sx.headerGradiant}>
                CRUISE
              </Box>{" "}
              SIGN UP FORM
            </Typography>
            <Box
              variant="text"
              sx={{ my: 2, color: "#fff", textAlign: "center" }}
            >
              Please fill out the form and submit it
            </Box>

            <Box sx={sx.formCard}>
              <Typography variant="h2" sx={sx.cardsHeader}>
                <Box component="span" sx={sx.headerGradiant}>
                  CRUISE{" "}
                </Box>
                INFO
              </Typography>

              <Box
                variant="text"
                sx={{ my: 2, color: "#fff", textAlign: "center" }}
              >
                Would you like to go on the cruise or not? Note: if you choose
                not to go on the cruise you will get the USDT instead.
              </Box>

              <FormControl sx={sx.formControl}>
                <InputLabel id="cruise-type" sx={{ color: "#fff" }}>
                  Cruise Options
                </InputLabel>
                <Select
                  labelid="cruise-label"
                  id="cruise-type"
                  label="Cruise options"
                  value={formState[REWARD_TYPE]}
                  onChange={(e) => handleChange(REWARD_TYPE)(e)}
                >
                  <MenuItem value={CRUISE}>I want to go</MenuItem>
                  <MenuItem value={USDT}>I do not want to go</MenuItem>
                </Select>
                <FormHelperText>{fieldErrorText(REWARD_TYPE)}</FormHelperText>
              </FormControl>
            </Box>

            {formState[REWARD_TYPE] == CRUISE && (
              <>
                <Box sx={sx.formCard}>
                  <Typography variant="h2" sx={sx.cardsHeader}>
                    <Box component="span" sx={sx.headerGradiant}>
                      CONTACT{" "}
                    </Box>
                    INFO
                  </Typography>

                  <Grid container spacing={3} sx={{ my: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={{ fontStyle: "italic" }}
                        variant="outlined"
                        label={labels[NAME]}
                        onChange={(e) => handleChange(NAME)(e)}
                        onBlur={() => validateField(NAME)}
                        fullWidth
                        helperText={fieldErrorText(NAME)}
                        error={errorState[NAME]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={{ fontStyle: "italic" }}
                        variant="outlined"
                        label={labels[EMAIL]}
                        onChange={(e) => handleChange(EMAIL)(e)}
                        onBlur={() => validateField(EMAIL)}
                        fullWidth
                        helperText={fieldErrorText(EMAIL)}
                        error={errorState[EMAIL]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={{ fontStyle: "italic" }}
                        variant="outlined"
                        label={labels[PHONE_NUMBER]}
                        onChange={(e) => handleChange(PHONE_NUMBER)(e)}
                        onBlur={() => validateField(PHONE_NUMBER)}
                        fullWidth
                        helperText={fieldErrorText(PHONE_NUMBER)}
                        error={errorState[PHONE_NUMBER]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={{ fontStyle: "italic" }}
                        variant="outlined"
                        label={labels[DISCORD_NAME]}
                        onChange={(e) => handleChange(DISCORD_NAME)(e)}
                        onBlur={() => validateField(DISCORD_NAME)}
                        fullWidth
                        helperText={fieldErrorText(DISCORD_NAME)}
                        error={errorState[DISCORD_NAME]}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={sx.formCard}>
                  <Typography variant="h2" sx={sx.cardsHeader}>
                    <Box component="span" sx={sx.headerGradiant}>
                      SHIPPING{" "}
                    </Box>
                    ADDRESS
                  </Typography>

                  <Grid container spacing={3} sx={{ my: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={{ fontStyle: "italic" }}
                        variant="outlined"
                        label={labels[ADDRESS]}
                        onChange={(e) => handleChange(ADDRESS)(e)}
                        onBlur={() => validateField(ADDRESS)}
                        fullWidth
                        helperText={fieldErrorText(ADDRESS)}
                        error={errorState[ADDRESS]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={{ fontStyle: "italic" }}
                        variant="outlined"
                        label={labels[CITY]}
                        onChange={(e) => handleChange(CITY)(e)}
                        onBlur={() => validateField(CITY)}
                        fullWidth
                        helperText={fieldErrorText(CITY)}
                        error={errorState[CITY]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={{ fontStyle: "italic" }}
                        variant="outlined"
                        label={labels[STATE]}
                        onChange={(e) => handleChange(STATE)(e)}
                        onBlur={() => validateField(STATE)}
                        fullWidth
                        helperText={fieldErrorText(STATE)}
                        error={errorState[STATE]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={{ fontStyle: "italic" }}
                        variant="outlined"
                        label={labels[ZIP_CODE]}
                        onChange={(e) => handleChange(ZIP_CODE)(e)}
                        onBlur={() => validateField(ZIP_CODE)}
                        fullWidth
                        helperText={fieldErrorText(ZIP_CODE)}
                        error={errorState[ZIP_CODE]}
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}

            {formState[REWARD_TYPE] == USDT && (
              <Box sx={sx.formCard}>
                <Typography variant="h2" sx={sx.cardsHeader}>
                  <Box component="span" sx={sx.headerGradiant}>
                    ADDRESS{" "}
                  </Box>
                  INFO
                </Typography>

                <TextField
                  sx={{ my: 2, fontStyle: "italic" }}
                  variant="outlined"
                  label={labels[WALLET_ADDRESS]}
                  onChange={(e) => handleChange(WALLET_ADDRESS)(e)}
                  onBlur={() => validateField(WALLET_ADDRESS)}
                  helperText={fieldErrorText(WALLET_ADDRESS)}
                  error={errorState[WALLET_ADDRESS]}
                />
              </Box>
            )}
          </Box>

          <Box sx={sx.footerBtnContainer}>
            <Button
              variant="contained"
              sx={{
                ...sx.btn,
              }}
              onClick={() => sendForm()}
            >
              Send Form
            </Button>
          </Box>

          {redeemLoading && !redeemTxEtherscan && <Box sx={sx.darkOverlay} />}

          {redeemLoading && redeemTxEtherscan && (
            <EtherscanModal txEtherScan={redeemTxEtherscan} />
          )}

          {!redeemLoading && (
            <Box
              sx={sx.closeBtn}
              onClick={() => !redeemLoading && setVisible(false)}
            >
              <img src={close} style={{ width: "100%" }} alt="Close" />
            </Box>
          )}
        </Container>
      </Box>
    </Modal>
  );
};

export default CruiseForm;
