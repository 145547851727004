import React, { forwardRef } from 'react';
import { Box, Typography } from '@mui/material';
import AboutItem from './componenets/AboutItem';
import a1 from '../../../../assets/images/about1.png';
import a2 from '../../../../assets/images/about2.png';

const BP1 = '@media (max-width: 1530px)';
const BP2 = '@media (max-width: 1800px)';
const BP3 = '@media (max-width: 1240px)';
const BP4 = '@media (max-width: 1200px)';

const sx = {
	root: {
		display: 'flex',
		position: 'relative',
		zIndex: '0',
		flexDirection: 'column',
		alignItems: 'center',
		pt: '120px',
		[BP4]: {
			pb: '120px',
			gap: '100px',
		},
	},
	title: {
		fontSize: '56px',
		maxWidth: '800px',
		fontFamily: 'bold-poppins',
		color: '#FFFFFF',
		px: '10px',
	},
	text: {
		mt: '16px',
		color: '#FFFFFF',
		[BP1]: {
			fontSize: '16px',
		},
		[BP2]: {
			maxWidth: '600px',
		},
		[BP3]: {
			maxWidth: '600px',
		},
		[BP4]: {
			textAlign: 'center',
			px: '20px',
		},
	},
	textr: {
		color: '#FFFFFF',
		mt: '150px',
		[BP1]: {
			fontSize: '16px',
		},
		[BP2]: {
			maxWidth: '600px',
		},
		[BP3]: {
			maxWidth: '600px',
		},
		[BP4]: {
			textAlign: 'center',
			px: '20px',
			mt: '0px',
		},
	},
};

const About = forwardRef((props, ref) => (
	<Box id="about" sx={sx.root}>
		<Typography align='center' ref={ref} sx={{ ...sx.title }}>Pluto Alliance - The Battle Has Begun</Typography>
		<AboutItem leftContent img={a1}>
			<Typography sx={sx.text}>
				The first shots fired shook the galaxy to it’s core. Mining vessels from Earth were caught extracting minerals from one of Pluto’s 5 moons. As a retaliatory response, rogue elements vaporized the Earthlings! 3333 lives were lost on that fateful day. These were known as the shots that started it all. Those who wanted a war seemed to be getting their way…
			</Typography>
		</AboutItem>
		<AboutItem img={a2}>
			<Typography sx={sx.textr}>
				Pluto leadership denied sanctioning the attack but the damage was done. Grainy footage of ships with unknown origin circulated as the REAL culprits but most dismissed the footage as a hoax. Earth was quick to mobilize their warships and called on all Galactic Union members to do the same. Mercury, Mars, Saturn and Neptune were quick to comply with Earth’s demands. Thus, the Pluto Eliminated Along w/ Cooperating Entities, or P.E.A.C.E Operation, was underway. Venus, Jupiter, and Uranus voiced their allegiance for the Pluto Alliance instead. The sides have been made clear.
			</Typography>
			<Typography sx={sx.text}>
				But rumors that it wasn’t even Pluto ships that fired the shots kept persisting. Some even claimed it was technology not even from this galaxy…
			</Typography>
		</AboutItem>
	</Box>
));

About.displayName = 'About';

export default About;
