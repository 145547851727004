
import { useCallback, useEffect, useMemo } from "react"
import { usePlutoV2ForgeContractPolygon, useZoom2ContractMainnet, usePlutoV2ContractMainnet, usePlutoV2Contract, usePolygonZoom2Contract, usePlutoV2PolygonContract, useZoom2Contract } from "./useContract";
import useWeb3Ctx from "./useWeb3Ctx";
import { useSelector, useDispatch } from "react-redux";
import { loadPolygonWallet } from "../store/wallet/actions";

export default function usePolygonWallet() {
    const { address } = useWeb3Ctx();
    // const address = "0xD67eBAADB51a5866f592cf043ebAF64CA01216C9"
    const forgeContract = usePlutoV2ForgeContractPolygon();

    // Metadata for polygon cards
    const zoom2ContractPolygon = usePolygonZoom2Contract();
    const plutoV2ContractPolygon = usePlutoV2PolygonContract();

    // Use hardcoded mainnet contract's tokenURI for goerli metadata 
    // because goerli cards are not minted so tokenURI returns error
    const zoom2ContractMainnet = useZoom2ContractMainnet();
    const plutoV2ContractMainnet = usePlutoV2ContractMainnet();

    // ETH chain contracts
    const plutoV2Contract = usePlutoV2Contract();

    const dispatch = useDispatch();
    const state = useSelector((state) => state.wallet.cards_polygon);
    const fetchWallet = useCallback(() => {
        if (forgeContract && address && zoom2ContractMainnet && zoom2ContractPolygon && plutoV2ContractPolygon && plutoV2ContractMainnet) {
            (async () => {
                try {
                    await dispatch(loadPolygonWallet(
                        forgeContract,
                        address,
                        plutoV2Contract,
                        plutoV2ContractPolygon,
                        zoom2ContractPolygon,
                        plutoV2ContractMainnet,
                        zoom2ContractMainnet))();
                } catch (error) {
                    console.log(error);
                }
            })();
        }
    }, [plutoV2Contract, forgeContract, dispatch, address, zoom2ContractPolygon, plutoV2ContractPolygon, zoom2ContractMainnet, plutoV2ContractMainnet]);

    useEffect(() => {
        if (address) {
            fetchWallet();
        }
    }, [address])

    return useMemo(() => {
        if (!address) {
            return { ...state, forgeable: [], metadata: [], forgeableOnMainnet: [], fetchWallet }
        } else
            return { ...state, fetchWallet }
    }, [state, address]);
}