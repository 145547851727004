import React, { useState, useEffect } from "react";
import { toast } from "react-toast";
import {
  Box,
  Modal,
  Typography,
  Container,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  InputLabel,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import EtherscanModal from "../../../components/EtherscanModal";
import close from "../../../assets/images/close-icon-white.svg";

import {
  getMerchTypeById,
  MERCH_ALL,
  MERCH_BEANIE,
  MERCH_HOODIE,
  MERCH_TEE,
} from "../constants";

import {
  NAME,
  EMAIL,
  PHONE_NUMBER,
  DISCORD_NAME,
  CONTACT_PARAMS,
  ADDRESS,
  CITY,
  STATE,
  ZIP_CODE,
  SHIPPING_PARAMS,
  MERCH_TYPE,
  TEE_SIZE,
  HOODIE_SIZE,
  ALL_MERCH_PARAMS,
  REWARD_ID,
} from "../constants/form";

import { VALID_PHONE, VALID_EMAIL, REQUIRED } from "../constants/fieldError";

const BP1 = "@media (max-width: 899px)";
const BP2 = "@media (max-width: 719px)";

const sx = {
  root: {
    height: "100%",
    minHeight: "100%",
    overflow: "auto",
    py: "44px",
    pb: "94px",
    pt: "120px",
    backgroundColor: "rgba(0,0,0,0.8)",
    transition: "all .3s",
  },
  title: {
    color: "#fff",
    fontFamily: "bold-poppins",
    fontSize: {
      xs: "26px",
      md: "34px",
      lg: "48px",
    },
    lineHeight: {
      xs: "30px",
      md: "40px",
      lg: "50px",
    },
  },
  headerGradiant: {
    backgroundImage: "linear-gradient(180deg,#27DFC7,#fff)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
  },
  closeBtn: {
    width: "24px",
    height: "24px",
    position: "fixed",
    top: "80px",
    right: "10%",
    cursor: "pointer",
    pointerEvents: "auto",
    transition: "all .3s",
    [BP1]: {
      top: "20px",
    },
  },
  url: {
    textDecoration: "none",
    color: "#19a8b4",
    fontWeight: "700",
  },
  darkOverlay: {
    zIndex: 100,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.8)",
    pointerEvents: "none",
  },
  formCard: {
    width: {
      xs: "90%",
      sm: "80%",
      md: "70%",
      lg: "60%",
    },
    background:
      "transparent linear-gradient(105deg, #00000080 0%, #27DFC733 100%) 0% 0% no-repeat padding-box",
    border: "1px solid #27DFC7",
    borderRadius: "6px",
    py: 2,
    px: {
      xs: 2,
      sm: 3,
      md: 4,
      lg: 5,
    },
    my: 2,
    mx: "auto",
    display: "flex",
    flexDirection: "column",
  },
  cardsHeader: {
    textAlign: "center",
    color: "#fff",
    fontFamily: "bold-poppins",
  },
  formControl: {
    my: 2,
    minWidth: {
      xs: "100%",
      sm: "300px",
    },
    mx: "auto",
    p: 0,
  },
  footerBtnContainer: {
    display: "flex",
    alignItems: "center",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    background: "#FFFFFF66 0% 0% no-repeat padding-box",
    backdropFilter: "blur(10px)",
    height: "88px",
    width: "100%",
  },
  btn: {
    minWidth: "210px",
    fontSize: "0.9rem",
    fontWeight: "600",
    mx: "auto",
    opacity: 1,
    transition: "opacity 0.1s linear",
    backgroundColor: "#27DFC7",
    "&:hover": {
      backgroundColor: "#27DFC7",
      opacity: 0.9,
    },
    "&:disabled": {
      color: "#fff",
      backgroundColor: "#C7C6C6 !important",
      cursor: "not-allowed",
      opacity: 1,
    },
    zIndex: "0",
  },
};

const initialState = {
  [REWARD_ID]: null,
  [MERCH_TYPE]: null,
  [TEE_SIZE]: null,
  [HOODIE_SIZE]: null,

  [NAME]: null,
  [EMAIL]: null,
  [PHONE_NUMBER]: null,
  [DISCORD_NAME]: null,

  [ADDRESS]: null,
  [CITY]: null,
  [STATE]: null,
  [ZIP_CODE]: null,
};

const initialErrorState = {
  [TEE_SIZE]: null,
  [HOODIE_SIZE]: null,
  [NAME]: null,
  [EMAIL]: null,
  [PHONE_NUMBER]: null,
  [DISCORD_NAME]: null,
  [ADDRESS]: null,
  [CITY]: null,
  [STATE]: null,
  [ZIP_CODE]: null,
};

const allErrorState = {
  [TEE_SIZE]: true,
  [HOODIE_SIZE]: true,
  [NAME]: true,
  [EMAIL]: true,
  [PHONE_NUMBER]: true,
  [DISCORD_NAME]: true,
  [ADDRESS]: true,
  [CITY]: true,
  [STATE]: true,
  [ZIP_CODE]: true,
};

const labels = {
  [NAME]: "Name",
  [EMAIL]: "Email",
  [PHONE_NUMBER]: "Phone Number",
  [DISCORD_NAME]: "Discord Name (with #'s)",
  [ADDRESS]: "Address",
  [CITY]: "City",
  [STATE]: "State",
  [ZIP_CODE]: "Zip Code",
};

const sizes = ["S", "M", "L", "XL", "2XL", "3XL"];

const errorTexts = {
  [NAME]: REQUIRED,
  [EMAIL]: REQUIRED,
  [PHONE_NUMBER]: REQUIRED,
  [DISCORD_NAME]: REQUIRED,
  [ADDRESS]: REQUIRED,
  [CITY]: REQUIRED,
  [STATE]: REQUIRED,
  [ZIP_CODE]: REQUIRED,
  [TEE_SIZE]: REQUIRED,
  [HOODIE_SIZE]: REQUIRED,
};

const MerchForm = ({
  visible,
  setVisible,
  redeemKyc,
  redeemLoading,
  redeemTxEtherscan,
}) => {
  const [formState, setFormState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);

  useEffect(() => {
    if (!visible) {
      setFormState(initialState);
    } else {
      setErrorState(initialErrorState);
      setFormState({
        ...initialState,
        [MERCH_TYPE]: getMerchTypeById(visible),
        [REWARD_ID]: visible,
      });
    }
  }, [visible]);

  // console.log(formState[MERCH_TYPE]);

  const handleChange = (fieldName) => (event) => {
    // console.log(event.target.value);
    setFormState({
      ...formState,
      [fieldName]: event.target.value,
    });
  };

  const fieldErrorText = (fieldName) => {
    return errorState[fieldName]
      ? typeof errorState[fieldName] == "string"
        ? errorState[fieldName]
        : errorTexts[fieldName]
      : "";
  };

  const validateField = (fieldName, setField = true) => {
    const value = formState[fieldName];
    let notValid = value === null || value === "";

    if (!notValid) {
      if (fieldName == PHONE_NUMBER) {
        if (!isPossiblePhoneNumber(value)) {
          notValid = VALID_PHONE;
        } else {
          notValid = false;
        }
      }
      if (fieldName == EMAIL) {
        if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
          notValid = VALID_EMAIL;
        } else {
          notValid = false;
        }
      }
    }

    if (setField) {
      setErrorState({ ...errorState, [fieldName]: notValid });
    }
    return notValid;
  };

  const validateFields = () => {
    let valid = true;
    let newError = { ...errorState };

    CONTACT_PARAMS.forEach((fieldName) => {
      const notValid = validateField(fieldName, false);
      newError[fieldName] = notValid;
      if (notValid) {
        valid = false;
      }
    });

    SHIPPING_PARAMS.forEach((fieldName) => {
      const notValid = validateField(fieldName, false);
      newError[fieldName] = notValid;
      if (notValid) {
        valid = false;
      }
    });

    if (formState[MERCH_TYPE] == MERCH_ALL) {
      ALL_MERCH_PARAMS.forEach((fieldName) => {
        const notValid = validateField(fieldName, false);
        newError[fieldName] = notValid;
        if (notValid) {
          valid = false;
        }
      });
    } else if (formState[MERCH_TYPE] == MERCH_TEE) {
      const notValid = validateField(MERCH_TEE, false);
      newError[MERCH_TEE] = notValid;
      if (notValid) {
        valid = false;
      }
    } else if (formState[MERCH_TYPE] == MERCH_HOODIE) {
      const notValid = validateField(MERCH_HOODIE, false);
      newError[MERCH_TEE] = notValid;
      if (notValid) {
        valid = false;
      }
    }

    setErrorState(newError);
    return valid;
  };

  const sendForm = () => {
    if (!validateFields()) return;
    if (redeemLoading) return;

    redeemKyc(formState);
  };

  return (
    <Modal
      open={visible != null || visible == true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && !redeemLoading) {
          setVisible(false);
        }
      }}
    >
      <Box sx={sx.root}>
        <Container>
          <Box sx={sx.content}>
            <Typography variant="heading1" sx={sx.title}>
              <Box component="span" sx={sx.headerGradiant}>
                MERCH
              </Box>{" "}
              SIGN UP FORM
            </Typography>
            <Box
              variant="text"
              sx={{ my: 2, color: "#fff", textAlign: "center" }}
            >
              Please choose your sizes and fill out the form in order to get the
              merch
            </Box>

            <Box sx={sx.formCard}>
              <Typography variant="h2" sx={sx.cardsHeader}>
                <Box component="span" sx={sx.headerGradiant}>
                  MERCH{" "}
                </Box>
                SIZES
              </Typography>

              {(formState[MERCH_TYPE] == MERCH_ALL ||
                formState[MERCH_TYPE] == MERCH_BEANIE) && (
                <Grid container spacing={3} sx={{ alignItems: "center" }}>
                  <Grid item xs={4}>
                    <Typography>Beanie</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography sx={{ my: 2, textAlign: "start" }}>
                      One size fits all
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {(formState[MERCH_TYPE] == MERCH_ALL ||
                formState[MERCH_TYPE] == MERCH_TEE) && (
                <Grid container spacing={3} sx={{ alignItems: "center" }}>
                  <Grid item xs={4}>
                    <Typography>T-Shirt</Typography>
                  </Grid>
                  <Grid item xs={8} sx={{ textAlign: "start" }}>
                    <FormControl
                      sx={sx.formControl}
                      error={errorState[TEE_SIZE]}
                    >
                      <InputLabel id="tshirt-size" sx={{ color: "#fff" }}>
                        Shirt Size
                      </InputLabel>
                      <Select
                        labelid="tshirt-size"
                        id="tshirt-size"
                        label="tshirt-size"
                        value={formState[TEE_SIZE] || ""}
                        onChange={(e) => handleChange(TEE_SIZE)(e)}
                        onBlur={() => validateField(TEE_SIZE)}
                      >
                        {sizes.map((size) => {
                          return (
                            <MenuItem key={size} value={size}>
                              {size}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>
                        {fieldErrorText(TEE_SIZE)}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              {(formState[MERCH_TYPE] == MERCH_ALL ||
                formState[MERCH_TYPE] == MERCH_HOODIE) && (
                <Grid container spacing={3} sx={{ alignItems: "center" }}>
                  <Grid item xs={4}>
                    <Typography>Hoodie</Typography>
                  </Grid>
                  <Grid item xs={8} sx={{ textAlign: "start" }}>
                    <FormControl
                      sx={sx.formControl}
                      error={errorState[HOODIE_SIZE]}
                    >
                      <InputLabel id="hoodie-size" sx={{ color: "#fff" }}>
                        Hoodie Size
                      </InputLabel>
                      <Select
                        labelid="hoodie-size"
                        id="hoodie-size"
                        label="hoodie-size"
                        value={formState[HOODIE_SIZE] || ""}
                        onChange={(e) => handleChange(HOODIE_SIZE)(e)}
                        onBlur={() => validateField(HOODIE_SIZE)}
                        error={errorState[HOODIE_SIZE]}
                      >
                        {sizes.map((size) => {
                          return (
                            <MenuItem key={size} value={size}>
                              {size}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>
                        {fieldErrorText(HOODIE_SIZE)}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </Box>

            <Box sx={sx.formCard}>
              <Typography variant="h2" sx={sx.cardsHeader}>
                <Box component="span" sx={sx.headerGradiant}>
                  CONTACT{" "}
                </Box>
                INFO
              </Typography>

              <Grid container spacing={3} sx={{ my: 2 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ fontStyle: "italic" }}
                    variant="outlined"
                    label={labels[NAME]}
                    onChange={(e) => handleChange(NAME)(e)}
                    onBlur={() => validateField(NAME)}
                    fullWidth
                    helperText={fieldErrorText(NAME)}
                    error={errorState[NAME]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ fontStyle: "italic" }}
                    variant="outlined"
                    label={labels[EMAIL]}
                    onChange={(e) => handleChange(EMAIL)(e)}
                    onBlur={() => validateField(EMAIL)}
                    fullWidth
                    helperText={fieldErrorText(EMAIL)}
                    error={errorState[EMAIL]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ fontStyle: "italic" }}
                    variant="outlined"
                    label={labels[PHONE_NUMBER]}
                    onChange={(e) => handleChange(PHONE_NUMBER)(e)}
                    onBlur={() => validateField(PHONE_NUMBER)}
                    fullWidth
                    helperText={fieldErrorText(PHONE_NUMBER)}
                    error={errorState[PHONE_NUMBER]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ fontStyle: "italic" }}
                    variant="outlined"
                    label={labels[DISCORD_NAME]}
                    onChange={(e) => handleChange(DISCORD_NAME)(e)}
                    onBlur={() => validateField(DISCORD_NAME)}
                    fullWidth
                    helperText={fieldErrorText(DISCORD_NAME)}
                    error={errorState[DISCORD_NAME]}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={sx.formCard}>
              <Typography variant="h2" sx={sx.cardsHeader}>
                <Box component="span" sx={sx.headerGradiant}>
                  SHIPPING{" "}
                </Box>
                ADDRESS
              </Typography>

              <Grid container spacing={3} sx={{ my: 2 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ fontStyle: "italic" }}
                    variant="outlined"
                    label={labels[ADDRESS]}
                    onChange={(e) => handleChange(ADDRESS)(e)}
                    onBlur={() => validateField(ADDRESS)}
                    fullWidth
                    helperText={fieldErrorText(ADDRESS)}
                    error={errorState[ADDRESS]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ fontStyle: "italic" }}
                    variant="outlined"
                    label={labels[CITY]}
                    onChange={(e) => handleChange(CITY)(e)}
                    onBlur={() => validateField(CITY)}
                    fullWidth
                    helperText={fieldErrorText(CITY)}
                    error={errorState[CITY]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ fontStyle: "italic" }}
                    variant="outlined"
                    label={labels[STATE]}
                    onChange={(e) => handleChange(STATE)(e)}
                    onBlur={() => validateField(STATE)}
                    fullWidth
                    helperText={fieldErrorText(STATE)}
                    error={errorState[STATE]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ fontStyle: "italic" }}
                    variant="outlined"
                    label={labels[ZIP_CODE]}
                    onChange={(e) => handleChange(ZIP_CODE)(e)}
                    onBlur={() => validateField(ZIP_CODE)}
                    fullWidth
                    helperText={fieldErrorText(ZIP_CODE)}
                    error={errorState[ZIP_CODE]}
                    type="number"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={sx.footerBtnContainer}>
            <Button
              variant="contained"
              sx={{
                ...sx.btn,
              }}
              onClick={() => sendForm()}
            >
              Send Form
            </Button>
          </Box>

          {redeemLoading && !redeemTxEtherscan && <Box sx={sx.darkOverlay} />}

          {redeemLoading && redeemTxEtherscan && (
            <EtherscanModal txEtherScan={redeemTxEtherscan} />
          )}

          {!redeemLoading && (
            <Box
              sx={sx.closeBtn}
              onClick={() => !redeemLoading && setVisible(false)}
            >
              <img src={close} style={{ width: "100%" }} alt="Close" />
            </Box>
          )}
        </Container>
      </Box>
    </Modal>
  );
};

export default MerchForm;
