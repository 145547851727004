import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import api from '../lib/axios/api';

/* eslint-disable no-underscore-dangle */
const store = createStore(
	reducers,
	applyMiddleware(thunk.withExtraArgument({ api })),
	// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
