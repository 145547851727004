import React, { useEffect, useState, useContext } from 'react';
import {
	Box, Button, Modal, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useSaleContract } from '../../../hooks/useContract';
import eth from '../../../assets/images/eth-icon.svg';
import closeBtn from '../../../assets/images/close-icon.svg';
import ph1 from '../../../assets/images/placeholders/ph1.png';
import ph2 from '../../../assets/images/placeholders/ph2.png';
import ph3 from '../../../assets/images/placeholders/ph3.png';
import Web3Ctx from '../../../context/Web3Ctx';

const placeholders = [ph1, ph2, ph3];

const BP1 = '@media (max-width: 982px)';

const sx = {
	root: {
		minHeight: '100%',
		backgroundColor: 'background.light',
		pt: '160px',
		px: '20px',
		position: 'relative',
		pointerEvents: 'none',
		transition: 'all .3s',
		overflow: 'scroll',
		display: 'block',
		[BP1]: {
			pt: '80px',
		},
	},
	content: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		flexWrap: 'wrap',
		gap: '0 50px',
		overflowY: 'scroll',
		transition: 'all .3s',
		position: 'relative',
		[BP1]: {
			pb: '80px',
		},
	},
	card: {
		width: '100%',
		maxWidth: '407px',
		aspectRatio: '407 / 527',
		backgroundColor: 'primary.main',
		borderRadius: '12px',
		pointerEvents: 'auto',
		overflow: 'hidden',
	},
	rightContainer: {
		width: '100%',
		maxWidth: '486px',
		pointerEvents: 'auto',
	},
	title: {
		transition: 'all .3s',
		[BP1]: {
			textAlign: 'center',
			mt: '16px',
		},
	},
	infoRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '0 16px',
		mt: '13px',
	},
	infoLabel: {
		color: 'background.infoBox',
		minWidth: '50px',
	},
	infoValue: {
		fontSize: '20px',
		fontWeight: 600,
	},
	priceContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '0 4px',
	},
	counterContainer: {
		minWidth: '80px',
		maxWidth: '90px',
		height: '50px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: '0 8px',
		borderStyle: 'solid',
		borderWidth: '2px',
		borderColor: 'primary.main',
		borderRadius: '4px',
		transition: 'all .3s',
		px: '10px',
		mt: '100px',
		[BP1]: {
			mx: 'auto',
			mt: '40px',
		},
	},
	counterIcon: {
		fontSize: '20px',
		fontWeight: 600,
		cursor: 'pointer',
		userSelect: 'none',
	},
	counterText: {
		fontSize: '18px',
		fontWeight: 600,
		minWidth: '12px',
		textAlign: 'center',
	},
	btn: {
		mt: '24px',
		px: '24px',
	},
	closeBtnContainer: {
		width: '24px',
		height: '24px',
		position: 'fixed',
		top: '80px',
		right: '10%',
		cursor: 'pointer',
		pointerEvents: 'auto',
		transition: 'all .3s',
		[BP1]: {
			top: '20px',
		},
	},
};

const BuyModal = ({ visible, setVisible }) => {
	const price = useSelector((state) => state.info.data.price); // Use this!

	const [quantity, setQuantity] = useState(1);
	const [currentImage, setCurrentImage] = useState(0);

	const { ethersProvider, address } = useContext(Web3Ctx);
	const skeletonContract = useSaleContract();

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentImage((state) => {
				if (state === placeholders.length - 1) {
					return 0;
				}
				return state + 1;
			});
		}, 3000);
		return () => {
			clearInterval(interval);
		};
	}, []);

	const handleQuantityChange = (inc) => {
		if (inc) {
			setQuantity((state) => state + 1);
		} else {
			setQuantity((state) => {
				if (state > 1) {
					return state - 1;
				}
				return state;
			});
		}
	};

	const handleClose = () => {
		setVisible(false);
	};

	const handleBuy = async () => {
		try {
			const signer = skeletonContract.connect(ethersProvider.getSigner());
			// setSigning(true);

			// const k = await skeletonContract.mint(
			// 	{
			// 		value: 1 * 10 ** 18,  // 1 eth .. use bigNumber here
			// 		// gasLimit, // set manual gas limit if you specify this
			// 	},
			// ).catch((e) => {
			// 	console.log('Error');
			// 	console.log(e);
			// });

			// const k = await skeletonContract['mint()'](
			// 	{
			// 		value: 1 * 10 ** 18,  // 1 eth .. use bigNumber here
			// 		// gasLimit // set manual gas limit if you specify this
			// 	},
			// ).catch((e) => {
			// 	console.log('Error');
			// 	console.log(e);
			// });

			const k = await skeletonContract['mint(uint256 numberOfCards)'](
				1,
				{
					value: 0.1 * 10 ** 18,  // 1 eth .. use bigNumber here
					// gasLimit // set manual gas limit if you specify this
				},
			).catch((e) => {
				console.log('Error');
				console.log(e);
			});

			console.log(k);

			// const tx = await signer['mint()'](quantity, address)
			// // const tx = await signer['mint(uint256,uint256)'](0.1, 1)
			// 	.catch((e) => {
			// 		console.log('Nona');
			// 		console.log(e);
			// 	});
			// setSigning(false);
			// if (tx) {
			// 	// setTxHash(tx.hash);
			// 	// setInProgress(true);
			// 	const res = await tx.wait().catch((e) => console.log(e.reason));
			// 	console.log(res);
			// 	if (res) {
			// 		// setCardSent(true);
			// 	} else {
			// 		console.error('Some error occurred! : res not found!');
			// 		// toast.error('Some error occurred!');
			// 		// setInProgress(false);
			// 	}
			// } else {
			// 	console.error('Some error occurred! : tx not found!');
			// 	// toast.error('Some error occurred!');
			// 	// setInProgress(false);
			// }
		} catch (error) {
			console.log('Some error occurred!');
			console.log(error);
			// setInProgress(false);
		}
	};

	return (
		<Modal sx={{ overflowY: 'scroll' }} open={visible} onClose={handleClose} BackdropProps={{ sx: { overflow: 'scroll' } }}>
			<Box sx={sx.root}>
				<Box sx={sx.content}>
					<Box sx={sx.card}>
						<img src={placeholders[currentImage]} style={{ width: '100%' }} alt='Random card' />
					</Box>
					<Box sx={sx.rightContainer}>
						<Typography variant='heading1' sx={sx.title}>Your collection name</Typography>
						<Box sx={{ ...sx.infoRow, mt: '41px' }}>
							<Typography sx={sx.infoLabel}>Price:</Typography>
							<Box sx={sx.priceContainer}>
								<img src={eth} style={{ width: '11px' }} alt='Etherum' />
								<Typography sx={sx.infoValue}>{price}</Typography>
							</Box>
						</Box>
						<Box sx={sx.infoRow}>
							<Typography sx={sx.infoLabel}>Info:</Typography>
							<Typography sx={sx.infoValue}>5 layers</Typography>
						</Box>
						<Box sx={sx.counterContainer}>
							<Typography sx={{ ...sx.counterIcon, opacity: quantity > 1 ? 1 : 0.5 }} onClick={() => handleQuantityChange(false)}>-</Typography>
							<Typography sx={sx.counterText}>{quantity}</Typography>
							<Typography sx={sx.counterIcon} onClick={() => handleQuantityChange(true)}>+</Typography>
						</Box>
						<Box sx={{ [BP1]: { textAlign: 'center' } }}>
							<Button variant='contained' sx={sx.btn} onClick={handleBuy}>{`BUY FOR ETH ${Math.round(price * quantity * 100) / 100}`}</Button>
						</Box>
					</Box>
					<Box sx={sx.closeBtnContainer} onClick={handleClose}>
						<img src={closeBtn} style={{ width: '24px' }} alt='Close' />
					</Box>
				</Box>
			</Box>
		</Modal>
	);
};

/* eslint-disable react/forbid-prop-types */
BuyModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	setVisible: PropTypes.any.isRequired,
};

export default BuyModal;
