import { Box, Typography, Button, Stack, Container } from '@mui/material';
import closeButton from "../../../assets/images/clear.png"
const sx = {
  cardsContainer: {
    minHeight: '300px',
    width: '100%',
    border: '1px solid #27DFC7',
    borderRadius: '6px',
    p: 2,
    px:"5px",
    my: 2,
  },
  cardsHeader: {
    textAlign: 'center',
    color: '#fff',
    fontFamily: 'bold-poppins',
    fontSize:"48px",
    lineHeight: "50px",
    textTransform:"uppercase",
    mb: 2,
  },
  headerGradiant: {
    backgroundImage: 'linear-gradient(180deg,#27DFC7,#fff)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  alertContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor:"rgba(0,0,0,0.85)",
    px:"10px"
  },
  bnt:{
    fontSize: "0.9rem",
    fontWeight: "600",
    mx: "auto",
    mt:"30px",
    opacity: 1,
    transition: "opacity 0.1s linear",
    backgroundColor: "#27DFC7",
    "&:hover": {
      backgroundColor: "#27DFC7",
      opacity: 0.9,
    },
    "&:disabled": {
      color: "#fff",
      backgroundColor: "#C7C6C6 !important",
      cursor: "not-allowed",
      opacity: 1,
    },
    zIndex: "0",
  },
  closeButton:{
    position:"absolute",
    top:"5%",
    right:"5%"
  }
};
const MoveCardAlert = ({handleClick, closeModal}) => {
  return (
    <>
      <Box sx={sx.alertContainer}>
        <Button sx={sx.closeButton}><img src={closeButton} onClick={closeModal}/></Button>
        <Box sx={{textAlign:"center"}}>
          <Typography variant="h2" sx={sx.cardsHeader}>
            <Box component="span" sx={sx.headerGradiant}>
              Deposit
            </Box>{' '}
            Your Cards First

          </Typography>
          <Typography sx={{ color: '#fff', textAlign: 'center', maxWidth:"450px", margin:"0 auto"}}>
            To forge, you must first move your cards from the ethereum chain to
            the polygon chain.<br/><br/> Go to <a style={{color:"#27DFC7"}} href='https://nftbridge.galaxis.xyz/' target="blank">galaxis.bridge.xyz</a>, in the “Bridge” menu
            “Deposit” tab choose your cards to do that. <br/><br/>You can continue the
            forge when you’re done.
          </Typography>
          <Button
              variant="contained"
              sx={{
                ...sx.bnt,
              }}
              onClick={handleClick}
            >
              I moved my cards
            </Button>
        </Box>
      </Box>
    </>
  );
};

export default MoveCardAlert;
