import React, {
	useEffect, useState, useRef,
} from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import useContainerDimensions from '../../hooks/useContainerDimensions';
import EmbeddedCard from '../../components/EmbeddedCard/EmbeddedCard';

const sx = {
	root: {
		width: '100%',
		height: '100vh',
		overflow: 'hidden',
		backgroundColor: '#1E1E1E',
	},
	content: {
		p: '1.5rem',
		mx: 'auto',
	},
};

const Card = () => {
	const containerRef = useRef();
	const { width, height } = useContainerDimensions(containerRef);
	const [tokenId, setTokenId] = useState(null);
	const { tokenId: id } = useParams();

	useEffect(() => {
		const tmp = Number.parseInt(id, 10);
		if (!Number.isNaN(tmp)) {
			setTokenId(tmp);
		}
	}, [id]);

	const calculateContentStyles = () => {
		const h = height / 1.365;
		return {
			width: width < h ? `${100}%` : h,
			mt: width < h ? (height - (width * 1.365)) / 2 : 0,
		};
	};

	return (
		<Box sx={sx.root} ref={containerRef}>
			<Box sx={{ ...sx.content, ...(calculateContentStyles()) }}>
				{tokenId !== null && <EmbeddedCard tokenId={tokenId} />}
			</Box>
		</Box>
	);
};
export default Card;
