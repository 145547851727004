import { Box, Typography, Grid, Button, Stack, Container } from "@mui/material";
import { planets } from "../constants";

const sx = {
  gridContainer: {
    my: 3,
    mx: "auto",
    justifyContent: "center",
  },
  planetBtn: (enable) => (theme) => ({
    background: enable ? "#27DFC7" : "transparent",
    border: "2px solid #27DFC7",
    borderRadius: "60px",
    color: "#fff",
    py: 1,
    px: 2,
    fontFamily: "bold-poppins",
    "&:hover": {
      background: "#27DFC7",
      cursor: "pointer",
    },
    fontSize: {
      xs: "12px",
      md: "14px",
      lg: "16px",
    },
  }),
};
const PlanetBtns = ({ planetId, onClick }) => {
  return (
    <Grid container sx={sx.gridContainer}>
      {planets.map((planet) => {
        return (
          <Grid
            item
            sx={{
              p: {
                xs: 1,
                md: 1,
              },
              textAlign: "center",
            }}
            key={planet.name}
            onClick={() => {
              if (onClick) {
                onClick(planet.id);
              }
            }}
          >
            <Box sx={sx.planetBtn(planet.id == planetId)}>{planet.name}</Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PlanetBtns;
