import React, { useEffect, useState } from "react";
import useWeb3Ctx from "../../hooks/useWeb3Ctx";
import useRedeemKyc from "../../hooks/useRedeemKyc";
import config from "../../config";
import MoveCardAlert from "./components/MoveCardAlert";
import { Box, Typography, Button, Stack, Container } from "@mui/material";
import { SpinnerDiamond as Spinner } from "spinners-react";
import { ArrowBack } from "@mui/icons-material";
import { toast } from "react-toast";
import bgMint2 from "../../assets/images/mint-background-1.png";

import { usePlutoV2ForgeContractPolygon } from "../../hooks/useContract";
import useForgeRewards from "../../hooks/useForgeRewards";
import { handleWalletError } from "../../utils";
import {Modal } from "@mui/material";
import EtherscanModal from "../../components/EtherscanModal";
import CardsContainer from "./components/CardsContainer";
import ForgeCardsContainer from "./components/ForgeCardsContainer";
import ForgeRewardsContainer from "./components/ForgeRewardsContainer";
import CruiseForm from "./components/CruiseForm";
import MerchForm from "./components/MerchForm";
import Stepper from "./components/Stepper";
import PlanetBtns from "./components/PlanetBtns";

import { planets, framedIds, mysteryNftVideo } from "./constants";
//import mockMetadata from "./constants/mockMetadata";
import usePolygonWallet from "../../hooks/usePolygonWallet";

const BP1 = "@media (max-width: 539px)";
const BP2 = "@media (max-width: 719px)";
const BP3 = "@media (max-width: 899px)";
const BP4 = "@media (max-width: 637px)";

const sx = {
  root: {
    height: "100%",
    minHeight: "100vh",
    background: `url(${bgMint2})`,
    backgroundColor: "#000",
    backgroundSize: "cover",
    backgroundPosition: "top",
    py: "44px",
    pb: "94px",
    position: "relative",
    transition: "all .3s",
    [BP3]: {
      // px: "85px",
    },
    [BP2]: {
      // px: "25px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
    mb: 4,
  },
  title: {
    color: "#fff",
    fontFamily: "bold-poppins",
    fontSize: {
      xs: "26px",
      md: "34px",
      lg: "48px",
    },
    lineHeight: {
      xs: "30px",
      md: "40px",
      lg: "50px",
    },
  },
  gridContainer: {
    my: 3,
    mx: "auto",
    maxWidth: "80%",
    justifyContent: "center",
  },
  btn: {
    minWidth: "210px",
    fontSize: "0.9rem",
    fontWeight: "600",
    mx: "auto",
    transition: "opacity 0.1s linear",
    backgroundColor: "#27DFC7",
    "&:hover": {
      backgroundColor: "#27DFC7",
      opacity: 0.9,
    },
    "&:disabled": {
      color: "#fff",
      backgroundColor: "#C7C6C6 !important",
      cursor: "not-allowed",
      opacity: 1,
    },
    zIndex: "0",
  },
  headerGradiant: {
    backgroundImage: "linear-gradient(180deg,#27DFC7,#fff)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  footerBtnContainer: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    background: "#FFFFFF66 0% 0% no-repeat padding-box",
    backdropFilter: "blur(10px)",
    height: "88px",
    width: "100%",
  },
  footerBackBtn: {
    position: "absolute",
    left: 0,
    color: "#fff",
    fontSize: {
      sm: "14px",
      md: "16px",
      lg: "16px",
    },
    textTransform: "unset",
    ml: {
      sm: 2,
      md: 3,
      lg: 5,
    },
  },
  backBtnText: {
    [BP4]: {
      display: "none",
    },
  },
  link: {
    color: "#27DFC7",
  },
  darkOverlay: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    zIndex: 100,
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.9)",
  },
  url: {
    textDecoration: "none",
    color: "#19a8b4",
    fontWeight: "700",
  },
  claimRewardContainer: {
    background: "#DFDF274D 0% 0% no-repeat padding-box",
    border: "1px solid #DFDF27",
    borderRadius: "8px",
    backdropFilter: "blur(10px)",
    px: 5,
    py: 1,
    mx: "auto",
    color: "#fff",
    width: {
      xs: "90%",
      sm: "80%",
      md: "70%",
      lg: "60%",
    },
  },
};

const openseaUrl = "https://opensea.io/collection/pluto2";

const Forge = () => {
  // const metadata = mockMetadata;
  const { isLoading, forgeable: metadata,forgeableOnMainnet, fetchWallet } = usePolygonWallet();
  const { rewardArray, hasReward, fetchInfo } = useForgeRewards();
  // console.log(rewardArray, hasReward)
  // usePolygonWallet();
  // const address =  "0xD67eBAADB51a5866f592cf043ebAF64CA01216C9" 
  const { address,handleConnect, setChain } = useWeb3Ctx();
  const { redeemKyc, loading, txEtherscan: redeemTxEtherscan } = useRedeemKyc();

  const [approveInProgress, setApproveInProgress] = useState(false);
  const [txInProgress, setTxInProgress] = useState(false);
  const [txEtherScan, setTxEtherScan] = useState(null);

  const [step, setStep] = useState(1);
  const [planetId, setPlanetId] = useState(1);

  const [planetFirsts, setPlanetFirsts] = useState([]);
  const [planetMiddles, setPlanetMiddles] = useState([]);
  const [planetLasts, setPlanetLasts] = useState([]);

  const [firstId, setFirstId] = useState(null);
  const [middleId, setMiddleId] = useState(null);
  const [lastId, setLastId] = useState(null);

  const [showCruiseForm, setShowCruiseForm] = useState(null);
  const [showMerchform, setShowMerchForm] = useState(null);
  // const [showClaimTooltip, setShowClaimTooltip] = useState(false);

  const [isThereMainnetCardWhenForge, setIsThereMainnetCardWhenForge] = useState(false)

  const forgeContract = usePlutoV2ForgeContractPolygon();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!address) {
      setStep(1);
      setPlanetId(null);
      setFirstId(null);
      setMiddleId(null);
      setLastId(null);
    } else {
      setStep(2);
    }
  }, [address]);

  useEffect(() => {
    if (planetId) {
      setFirstId(null);
      setMiddleId(null);
      setLastId(null);

      const planet = getPlanet(planetId);
      const first = planet.background.first;
      const middle = planet.background.middle;
      const last = planet.background.last;
      let metadataWithMainnet = [];
      if(forgeableOnMainnet){
        for (let index = 0; index < forgeableOnMainnet.length; index++) {
          const element = forgeableOnMainnet[index];
          element.isOnMainnet = true;
          metadataWithMainnet.push(element);
        }
      }

      let metadataWithMainnetAndPolygon = [ ...metadata, ...metadataWithMainnet]

      setPlanetFirsts(metadataWithMainnetAndPolygon.filter((data) => first.includes(data.tokenId)));
      setPlanetMiddles(
        metadataWithMainnetAndPolygon.filter((data) => middle.includes(data.tokenId))
      );
      setPlanetLasts(metadataWithMainnetAndPolygon.filter((data) => last.includes(data.tokenId)));
    }
  }, [planetId,metadata]);

  const resetPlanets = () => {
    setPlanetId(1);
    setFirstId(null);
    setMiddleId(null);
    setLastId(null);
  };

  const footerBtnText = () => {
    if (step == 2 && planetId != null) {
      //return `SEE ${getPlanet(planetId).name} CARDS`;
      return "PROCEED";
    } else if (step == 3) {
      return "FORGE";
    } else if (step == 4) {
      return "FORGE MORE";
    }
  };

  const footerDisable = () => {
    if (step == 2) {
      return !(middleId && lastId && firstId);
    } else if (step == 3) {
      return !(middleId && lastId && firstId);
    } else if (step == 4) {
      return false;
    }
    return true;
  };

  const footerOnClick = () => {
    if (step == 2 && !footerDisable()) {
      let forgeableCards = [...planetFirsts, ...planetMiddles, ...planetLasts]
      let filteredForgeableCards = forgeableCards.filter((card) => card.id === firstId || card.id === middleId || card.id === lastId);
      let wrongCards = filteredForgeableCards.filter((card) => card.isOnMainnet);
      if(wrongCards.length > 0){
        setIsThereMainnetCardWhenForge(true);
        return
      }
      setStep(3);
    } else if (step == 3 && !footerDisable()) {
      onClickForge();
    } else if (step == 4) {
      setStep(2);
    }
  };

  const getPlanet = (id) => {
    return planets.find((planet) => planet.id == id);
  };

  const getMetadata = (id) => {
    return metadata.find((data) => data.tokenId == id);
  };

  const getNewImages = () => {
    const backgroundIds = [firstId, middleId, lastId];
    let framedId = null;
    backgroundIds.forEach((id) => {
      if (!framedId && framedIds.includes(id)) {
        framedId = id;
      }
    });

    const planet = getPlanet(planetId);

    const newImages = [
      {
        name: "2D Super Alien",
        image: `${config.FORGE_BASE_URL}/${planet["2d"]}`,
      },
      {
        name: "3D Super Alien",
        image: `${config.FORGE_BASE_URL}/${planet["3d"]}`,
      },
    ];

    if (!framedId) {
      newImages.push({
        name: "Mystery NFT",
        video: mysteryNftVideo,
      });
    } else {
      newImages.push({
        name: `Golden Framed #${framedId}`,
        image: `${config.FORGE_BASE_URL}/Framed/${framedId}.png`,
      });
    }

    return newImages;
  };

  const eligible = () => {
    return (
      planetFirsts.length > 0 &&
      planetMiddles.length > 0 &&
      planetLasts.length > 0
    );
  };

  const onRedeemSuccess = () => {
    setStep(4);
    setShowMerchForm(null);
    setShowCruiseForm(null);
    fetchInfo();
    fetchWallet();
  };

  const onClickForge = async () => {
    if (approveInProgress || txInProgress) return;
    setApproveInProgress(true);

    const success = await setChain(config.POLYGON_CHAIN_ID).catch(
      handleWalletError
    );
    // const success = true;
    if (success) {
      let gas = await forgeContract.estimateGas
        .forge(firstId, middleId, lastId)
        .catch(()=>{handleWalletError});

      // console.log(Number(gas));
      // console.log(Number(gas.mul(15).div(10)))

      if (gas) {
        await forgeContract
          .forge(firstId, middleId, lastId, { gasLimit: gas.mul(15).div(10) })
          .then((tx) => {
            setApproveInProgress(false);
            setTxInProgress(true);
            console.log(`${config.POLYGON_EXPLORER_URL}tx/${tx.hash}`);
            setTxEtherScan(`${config.POLYGON_EXPLORER_URL}tx/${tx.hash}`);
            return tx.wait().then((receipt) => {
              console.log("txReceipt: ", receipt);
              if (receipt && receipt.status === 1) {
                toast.success("Successfully Forged NFTs");
                setStep(4);
                fetchInfo();
                fetchWallet();
                resetPlanets();
                setTxEtherScan(null);
              } else {
                toast.error("Transaction Failed");
                setTxInProgress(false);
                setTxEtherScan(null);
              }
            });
          })
          .catch((e)=>{
            console.log(e,  " e error")
            if(e.error && e.error.message == "unknown account"){
              return;
            }else{
              handleWalletError(e)
            }});
      }
    }

    setTxInProgress(false);
    setApproveInProgress(false);
  };

  const onClickMovedCards = () =>{
    location.reload();
  }
  return (
    <Box sx={sx.root}>
      <Container>
        <Box sx={sx.content}>
          <Typography variant="heading1" sx={sx.title}>
            <Box component="span" sx={sx.headerGradiant}>
              FORGE
            </Box>{" "}
            YOUR ELIGIBLE CARDS
          </Typography>
          <Box
            variant="text"
            sx={{ my: 2, color: "#fff", textAlign: "center" }}
          >
            Please follow the instructions below to forge
          </Box>

          {hasReward && (step == 2 || step == 3) && (
            <Box sx={sx.claimRewardContainer} onClick={() => setStep(4)}>
              You have unclaimed rewards, click to{" "}
              <Box
                component="span"
                sx={{
                  ...sx.link,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setStep(4)}
              >
                claim
              </Box>{" "}
              them now
            </Box>
          )}

          <Box>
            <Stack
              direction="row"
              sx={{
                mt: 3,
                width: "100%",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Stepper id={1} text={"Connect your wallet"} step={step} />
              <Stepper id={2} text={"Choose your cards"} step={step} />
              <Stepper id={3} text={"Forge your cards"} step={step} />
              <Stepper id={4} text={"Receive reward"} step={step} />
            </Stack>
          </Box>

          {!address && (
            <Box display="flex" flexDirection="column" sx={{ my: 2, mt: 3 }}>
              <Button variant="contained" sx={sx.btn} onClick={handleConnect}>
                Connect Wallet
              </Button>
            </Box>
          )}
        </Box>

        {address && step == 2 && (
          <PlanetBtns planetId={planetId} onClick={(id) => setPlanetId(id)} />
        )}

        {isLoading && (
          <Box sx={{ textAlign: "center", mb: 2 }}>
            <Spinner color="#19A8B4" secondaryColor="rgba(255,255,255,0.5)" />
          </Box>
        )}

        {address && step == 2 && planetId != null && (
          <>
            {!isLoading && !eligible() && (
              <Typography sx={{ color: "#fff", textAlign: "center" }}>
                You are not eligible to forge in this category, buy more cards
                on{" "}
                <Box
                  component="a"
                  href={openseaUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={sx.link}
                >
                  opensea.io
                </Box>
              </Typography>
            )}
              <CardsContainer
              selectedId={firstId}
              background={"FIRST"}
              metadata={planetFirsts}
              onClick={(id) => setFirstId(id)}
            />
  
            
            <CardsContainer
              selectedId={middleId}
              background={"MIDDLE"}
              metadata={planetMiddles}
              onClick={(id) => setMiddleId(id)}
            />
            <CardsContainer
              selectedId={lastId}
              background={"LAST"}
              metadata={planetLasts}
              onClick={(id) => setLastId(id)}
            />
          </>
        )}

        {address && step == 3 && (
          <>
            <Typography sx={{ color: "#fff", textAlign: "center" }}>
              Finalize your selections:
            </Typography>
            <ForgeCardsContainer
              background={getPlanet(planetId).name}
              forgeCards={[
                getMetadata(firstId),
                getMetadata(middleId),
                getMetadata(lastId),
              ]}
            />
            <ForgeCardsContainer newCards={getNewImages()} />
          </>
        )}

        {address && step == 4 && (
          <>
            {rewardArray.length == 0 ? (
              <Typography sx={{ textAlign: "center" }}>
                Forge more for more rewards!
              </Typography>
            ) : (
              <Typography sx={{ color: "#fff", textAlign: "center" }}>
                Sign up to your rewards:
              </Typography>
            )}

            {rewardArray.map((reward) => {
              const rewardId = reward.id;
              const amount = reward.amount;

              // Cruise
              if (rewardId >= 1 && rewardId <= 4) {
                return (
                  <ForgeRewardsContainer
                    rewardType={"cruise"}
                    rewardId={rewardId}
                    amount={amount}
                    onClick={() => setShowCruiseForm(rewardId)}
                  />
                );
              }
              // Merch
              else if (rewardId >= 6 && rewardId <= 15) {
                return (
                  <ForgeRewardsContainer
                    rewardType={"merch"}
                    rewardId={rewardId}
                    amount={amount}
                    onClick={() => setShowMerchForm(rewardId)}
                  />
                );
              }
            })}
            {/* <ForgeRewardsContainer
              rewardType={"cruise"}
              rewardId={4}
              amount={1}
              onClick={() => setShowCruiseForm(4)}
            /> */}
          </>
        )}

        {address && (planetId || step == 4) && (
          <Box sx={sx.footerBtnContainer}>
            {step == 3 && (
              <Button
                sx={sx.footerBackBtn}
                onClick={() => setStep(2)}
                startIcon={<ArrowBack />}
              >
                <Box sx={sx.backBtnText}>Back to cards selection</Box>
              </Button>
            )}
            <Button
              variant="contained"
              sx={{
                ...sx.btn,
              }}
              onClick={() => footerOnClick()}
              disabled={footerDisable()}
            >
              {footerBtnText()}
            </Button>
          </Box>
        )}
      </Container>

      <CruiseForm
        visible={showCruiseForm}
        setVisible={() => setShowCruiseForm(null)}
        redeemKyc={(formParams) => {
          redeemKyc(formParams, () => onRedeemSuccess());
        }}
        redeemLoading={loading}
        redeemTxEtherscan={redeemTxEtherscan}
      />
      <MerchForm
        visible={showMerchform}
        setVisible={() => setShowMerchForm(null)}
        redeemKyc={(formParams) => {
          redeemKyc(formParams, () => onRedeemSuccess());
        }}
        redeemLoading={loading}
        redeemTxEtherscan={redeemTxEtherscan}
      />

      {approveInProgress && <Box sx={sx.darkOverlay} />}

      {txInProgress && <EtherscanModal txEtherScan={txEtherScan} />}
      <Modal
      open={isThereMainnetCardWhenForge}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setIsThereMainnetCardWhenForge(false)
        }
      }}
    ><MoveCardAlert handleClick={onClickMovedCards} closeModal={()=>setIsThereMainnetCardWhenForge(false)}/></Modal>
    </Box>
  );
};

export default Forge;
