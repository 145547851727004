import React, { useState } from "react";
import { Box, Modal, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { toast } from "react-toast";
import { useHistory } from "react-router-dom";
import {
  useCommunityContract,
  usePlutoContract,
} from "../../../../hooks/useContract";
import usePlutoWallet from "../../../../hooks/usePlutoWallet";
import { SpinnerRoundOutlined as Spinner } from "spinners-react";
import config from "../../../../config";
import Divider from "../../../../components/Divider";
import DragAndDrop from "./components/DragAndDrop";
import useWeb3Ctx from "../../../../hooks/useWeb3Ctx";

import closeBtn from "../../../../assets/images/close-icon-white.svg";
import bgMint1 from "../../../../assets/images/mint-background-1.png";
import bgMint2 from "../../../../assets/images/mint-background-2.png";
import ph1 from "../../../../assets/images/placeholders/ph1.png";
import ph2 from "../../../../assets/images/placeholders/ph2.png";
import ph3 from "../../../../assets/images/placeholders/ph3.png";

const placeholders = [ph1, ph2, ph3];

const BP1 = "@media (max-width: 982px)";
const BP2 = "@media (max-width: 719px)";

const sx = {
  root: {
    minHeight: "100%",
    backgroundColor: "#000",
    backgroundSize: "cover",
    content: `url(${bgMint2}) url(${bgMint1})`,
    background: `url(${bgMint1})`,
    pt: "110px",
    px: "20px",
    position: "relative",
    // transition: "all 1s",
    overflow: "scroll",
    display: "block",
    [BP1]: {
      pt: "80px",
    },
    zIndex: 1,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
  },
  title: {
    transition: "all .3s",
    color: "#fff",
    textAlign: "center",
    [BP1]: {
      mt: "16px",
    },
  },
  text: {
    color: "#fff",
    my: 4,
    maxWidth: "600px",
    alignSelf: "center",
  },
  closeBtnContainer: {
    zIndex: 1,
    width: "24px",
    height: "24px",
    position: "fixed",
    top: "80px",
    right: "10%",
    cursor: "pointer",
    pointerEvents: "auto",
    transition: "all .3s",
    [BP1]: {
      top: "20px",
    },
  },
  url: {
    textDecoration: "none",
    color: "#19a8b4",
    fontWeight: "700",
  },
  darkOverlay: {
    zIndex: 100,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.8)",
  },
};

const BurnMintModal = ({ visible, setVisible }) => {
  const history = useHistory();
  const { address } = useWeb3Ctx();
  const { isLoading } = usePlutoWallet();
  const plutoContract = usePlutoContract();
  const communityContract = useCommunityContract();

  const [approveInProgress, setApproveInProgress] = useState(false);
  const [txInProgress, setTxInProgress] = useState(false);
  const [txEtherScan, setTxEtherScan] = useState("asd");

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && !txInProgress && !approveInProgress) {
      setVisible(false);
    }
  };

  const handleMint = async (tokenId) => {
    if (!tokenId) return;
    if (!communityContract) {
      toast.error("Unable to load Community Contract");
      return;
    }
    console.log("burn:", tokenId);

    setApproveInProgress(true);

    await plutoContract["safeTransferFrom(address,address,uint256)"](
      address,
      communityContract.address,
      tokenId
    )
      .then((tx) => {
        setApproveInProgress(false);
        setTxInProgress(true);
        console.log(`${config.ETHERSCAN_URL}tx/${tx.hash}`);
        setTxEtherScan(`${config.ETHERSCAN_URL}tx/${tx.hash}`);
        return tx.wait().then((receipt) => {
          console.log("txReceipt: ", receipt);
          if (receipt && receipt.status === 1) {
            toast.success("Successfully Minted NFT");
            history.replace({ ...history.location, state: null });
            history.push({
              pathname: "/wallet",
            });
            setTxEtherScan(null);
          } else {
            toast.error("Transaction Failed");
            setTxInProgress(false);
            setTxEtherScan(null);
          }
        });
      })
      .catch(handleError);

    setTxInProgress(false);
    setApproveInProgress(false);
  };

  const handleError = (e) => {
    if (e.error && e.error.message) {
      toast.error(e.error.message);
    } else if (e.message) {
      toast.error(e.message);
    } else if (e.reason) {
      toast.error(e.reason);
    }
  };

  return (
    <Modal sx={{ overflowY: "scroll" }} open={visible} onClose={handleClose}>
      <Box
        sx={{
          ...sx.root,
          ...(txInProgress && { background: `url(${bgMint2})` }),
        }}
      >
        <Box sx={sx.content}>
          {!txInProgress ? (
            <>
              <Typography variant="heading2" sx={sx.title}>
                Burn to Mint
              </Typography>
              <Divider titleDivider />
              <Typography variant="text" sx={sx.text}>
                To initiate the Burn to Mint simply drag and drop your Pluto
                Alliance V1 NFT into the box and hit the button to get started.
                Your wallet provider will ask you to sign a transaction. It’s
                that easy!
              </Typography>
              <DragAndDrop handleMint={handleMint} />
            </>
          ) : (
            <>
              <Typography variant="heading1" sx={sx.title}>
                Transaction In Progress
              </Typography>
              <Divider titleDivider />
              <Typography variant="text" sx={{ my: 4, color: "#fff" }}>
                Please wait while your transaction is being processed. <br />
                {txEtherScan && (
                  <>
                    You can check the transaction status on{" "}
                    <Box
                      component="a"
                      href={txEtherScan}
                      sx={sx.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Etherscan
                    </Box>
                    .
                  </>
                )}
              </Typography>

              <Spinner
                color={"#19a8b4"}
                style={{ marginLeft: "auto", marginRight: "auto" }}
              />
            </>
          )}
        </Box>

        {approveInProgress && <Box sx={sx.darkOverlay} />}

        {!txInProgress && (
          <Box
            sx={sx.closeBtnContainer}
            onClick={() => !txInProgress && setVisible(false)}
          >
            <img src={closeBtn} style={{ width: "24px" }} alt="Close" />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

/* eslint-disable react/forbid-prop-types */
BurnMintModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.any.isRequired,
};

export default BurnMintModal;
