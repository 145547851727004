import { useEffect, useState } from 'react';

const getDimensions = (ref) => ({
	width: ref.current.offsetWidth,
	height: ref.current.offsetHeight,
});

const useContainerDimensions = (ref) => {
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	useEffect(() => {
		const handleResize = () => {
			if (ref.current) {
				setDimensions(getDimensions(ref));
			}
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [ref]);
	return dimensions;
};

export default useContainerDimensions;
