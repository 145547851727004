import { Box, Typography, Grid } from "@mui/material";

const sx = {
  cardsContainer: {
    minHeight: "260px",
    width: {
      xs: "100%",
      lg: "80%",
    },
    background:
      "transparent linear-gradient(105deg, #00000080 0%, #27DFC733 100%) 0% 0% no-repeat padding-box",
    border: "1px solid #27DFC7",
    borderRadius: "6px",
    p: 2,
    my: 2,
    mx: "auto",
  },
  cardsHeader: {
    textAlign: "center",
    color: "#fff",
    fontFamily: "bold-poppins",
    mb: 2,
  },
  nftContainer: (selected) => ({
    backgroundColor: "transparent",
    width: "100%",
    paddingTop: "137%",
    position: "relative",
    overflow: "hidden",
    perspective: "1px",
    mb: 1,
    borderRadius: "10px",
  }),
  nftImg: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  nftText: {
    color: "#fff",
    textAlign: "center",
    fontWeight: 600,
    fontSize: "14px",
  },
  headerGradiant: {
    backgroundImage: "linear-gradient(180deg,#27DFC7,#fff)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  gridContainer: {
    justifyContent: "center",
  },
};

const ForgeCardsContainer = ({ background, forgeCards, newCards }) => {
  return (
    <Box sx={sx.cardsContainer}>
      <Typography variant="h2" sx={sx.cardsHeader}>
        {background ? (
          <>
            <Box component="span" sx={sx.headerGradiant}>
              {background}
            </Box>{" "}
            FORGE{" "}
          </>
        ) : (
          <>
            YOUR{" "}
            <Box component="span" sx={sx.headerGradiant}>
              NEW
            </Box>{" "}
            CARDS
          </>
        )}
      </Typography>

      <Grid container spacing={3} sx={sx.gridContainer}>
        {forgeCards &&
          forgeCards.length > 0 &&
          forgeCards.map((card) => {
            return (
              <Grid item xs={4} sx={sx.gridItem} key={card.tokenId}>
                <Box sx={sx.nftContainer}>
                  <Box component="img" sx={sx.nftImg} src={card.image} />
                </Box>
                <Typography sx={sx.nftText}>{card.name}</Typography>
              </Grid>
            );
          })}

        {newCards &&
          newCards.length > 0 &&
          newCards.map((card) => {
            return (
              <Grid item xs={4} sx={sx.gridItem} key={card.name}>
                <Box sx={sx.nftContainer}>
                  {card.video ? (
                    <Box
                      component="video"
                      sx={sx.nftImg}
                      src={card.video}
                      autoPlay
                      loop
                      playsInline
                      muted
                    />
                  ) : (
                    <Box component="img" sx={sx.nftImg} src={card.image} />
                  )}
                </Box>
                <Typography sx={sx.nftText}>{card.name}</Typography>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

export default ForgeCardsContainer;
