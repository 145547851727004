import React, { forwardRef } from "react";
import { Box, Button, Typography, Slide } from "@mui/material";
import { useHistory } from "react-router-dom";
import { keyframes } from "@mui/system";
import useWeb3Ctx from "../../../../hooks/useWeb3Ctx";
import background from "../../../../assets/images/covernew.png";
import blackallian from "../../../../assets/images/blackallian.png";
import blueallian from "../../../../assets/images/blueallian.png";

const BP1 = "@media (max-width: 490px)";
const BP2 = "@media (max-width: 1250px)";
const BP3 = "@media (max-width: 1050px)";
const BP6 = "@media (max-width: 899px)";

const float = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
`;

const sx = {
  root: {
    width: "100%",
    minHeight: "700px",
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    pt: "110px",
    pb: "149px",
    px: "10px",
    position: "relative",
    gap: "180px",
    boxShadow: "0px -20px 20px 0 black inset, 0 20px 20px 0 black;",
  },
  overlay: {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: "0",
    top: "0",
    left: "0",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "none",
    [BP2]: {
      display: "block",
    },
  },
  content: {
    width: "100%",
    maxWidth: "525px",
    display: "flex",
    flexDirection: "column",
    zIndex: "0",
  },
  image: {
    width: "500px",
    animation: `${float} 6s infinite ease-in-out`,
    animationDelay: "1s",
  },
  badgeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "24px 24px",
    zIndex: "1",
    mb: "20px",
  },
  text: {
    textAlign: "center",
    zIndex: "1",
    [BP1]: {
      px: "17px",
    },
  },
  header: {
    pt: "10px",
    fontSize: "54px",
    fontWeight: "900",
    color: "#FFFFFF",
    wordWrap: "break-word",
    fontFamily: "bold-poppins",
  },
  headerGradiant: {
    backgroundImage: "linear-gradient(180deg,#27DFC7,#fff)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  description: {
    px: "16px",
    fontSize: "16px",
    lineHeight: "32px",
    color: "#FFFFFF",
    backgroundColor: "rgba(0,0,0,0.5)",
    boxShadow: "0 0 20px 20px rgba(0,0,0,0.5)",
    fontFamily: "semi-poppins",
  },
  description2: {
    mt: "27px",
    px: "10px",
    fontSize: "18px",
    lineHeight: "38px",
    color: "#FFFFFF",
  },
  smallText: {
    color: "#fff",
    backgroundColor: "rgba(0,0,0,0.5)",
    fontSize: "14px",
    mt: "28px",
    mb: "5px",
    mx: "auto",
    px: "5px",
    fontFamily: "roboto",
    textAlign: "center",
    zIndex: "1",
    boxShadow: "0 0 20px 20px rgba(0,0,0,0.5)",
    [BP1]: {
      px: "17px",
    },
  },
  errorText: {
    mt: "24px",
    fontSize: 14,
    fontWeight: 500,
    color: "#F85353",
    textAlign: "center",
  },
  btnContainer: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "20px 46px",
  },
  textContainer: {
    mt: "33px",
    alignSelf: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "20px 48px",
  },
  btn: {
    minWidth: "210px",
    fontSize: "0.9rem",
    fontWeight: "600",
    transition: "opacity 0.1s linear",
    "&:hover": {
      backgroundColor: "#27DFC7",
      opacity: 0.9,
    },
    "&:disabled": {
      color: "#fff",
      backgroundColor: "rgba(255,255,255,0.5)",
      cursor: "not-allowed",
    },
    zIndex: "0",
  },
  connectBtn: {
    mt: "24px",
    px: "25px",
    py: "11px",
    mx: "auto",
    borderColor: "#27DFC7",
    "&:hover": {
      backgroundColor: "#27DFC7",
      borderColor: "#27DFC7",
    },
  },
  discordBtn: {
    mt: "24px",
    backgroundColor: "#27DFC7",
    fontFamily: "semi-poppins",
  },
  mintBtn: {
    mt: "24px",
    backgroundColor: "#27DFC7",
  },
  kepek: {
    mt: "40px",
    width: "100%",
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "space-between",
    mr: "20px",
    [BP3]: {
      display: "none",
    },
  },
  infoContainer: {
    my: 3,
    p: 3,
    color: "#fff",
    textAlign: "center",
    background:
      "transparent linear-gradient(105deg, #00000080 0%, #27DFC733 100%) 0% 0% no-repeat padding-box",
    border: "1px solid #27DFC7",
    borderRadius: "8px",
    backdropFilter: "blur(2px)",
  },
  infoText1: {
    color: "#fff",
    fontSize: "28px",
    fontFamily: "semi-poppins",
    mb: 2,
  },
  link: {
    color: "#27DFC7",
  },
};

const bold = (children) => (
  <Box component="span" sx={{ fontFamily: "bold-poppins" }}>
    {children}
  </Box>
);
const JoinDiscord = () => (
  <>
    <Typography sx={{ ...sx.text, ...sx.description2 }}>
      Follow Pluto Alliance on{" "}
      {bold(
        <Box
          component="a"
          href={twitterUrl}
          target="_blank"
          rel="noopener noreferrer"
          sx={sx.link}
        >
          Twitter
        </Box>
      )}{" "}
      and stay up to date by joining our amazing community on{" "}
      {bold(
        <Box
          component="a"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          sx={sx.link}
        >
          Discord
        </Box>
      )}
    </Typography>
  </>
);

const url = "https://discord.gg/FZR4eJrFfY";
const ethercardUrl = "https://galaxis.xyz/";
const twitterUrl = "https://twitter.com/Pluto_Alliance";
const cruiseUrl = "https://www.theeventregistration.com/galacticcruise2023/2518207?i=chDjAMxKfbbOTxzwAmYdqPyh2MBxXH0w"
const Banner = forwardRef((props, ref) => {
  const { address, handleConnect, addNetwork } = useWeb3Ctx();
  const history = useHistory();

  const onAddBtnClick = async () => {
    const result = await addNetwork();
    console.log(result);
  };

  return (
    <Box sx={sx.root}>
      <Box sx={sx.kepek}>
        <Slide direction="right" in timeout={{ enter: 1000 }}>
          <Box component="img" sx={sx.image} src={blackallian} alt="Utility" />
        </Slide>
        <Slide direction="left" in timeout={{ enter: 1000 }}>
          <Box component="img" sx={sx.image} src={blueallian} alt="Utility" />
        </Slide>
      </Box>
      <Box sx={sx.content}>
        <Box sx={sx.overlay} />
        <Typography ref={ref} sx={{ ...sx.text, ...sx.header }}>
          <Box component="span" sx={{ ...sx.headerGradiant }}>
            FORGE
          </Box>{" "}
          TO GET THE REWARDS
        </Typography>
        <Typography sx={{ ...sx.text, ...sx.description }}>
          Forge your eligible cards to go on a cruise and receive Pluto Alliance
          merch. If you can’t forge, but want to go on the cruise, choose the
          Cruise With Us option:
        </Typography>
        <Box sx={sx.btnContainer}>
          {!address && (
            <Button
              variant="contained"
              sx={{ ...sx.btn, ...sx.mintBtn }}
              onClick={handleConnect}
            >
              CONNECT WALLET
            </Button>
          )}
          {address && (
            <Button
              variant="contained"
              sx={{ ...sx.btn, ...sx.mintBtn }}
              onClick={() => {
                history.push("/forge");
              }}
              disabled={!address}
            >
              FORGE
            </Button>
          )}
          <Button
            component="a"
            variant="outlined"
            color="light"
            sx={{ ...sx.btn, ...sx.connectBtn }}
            href={cruiseUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            CRUISE WITH US
          </Button>
        </Box>

        <Box sx={sx.infoContainer}>
          <Typography sx={sx.infoText1}>
            <Box component="span" sx={{ ...sx.headerGradiant }}>
              10,000
            </Box>{" "}
            ALIENS{" "}
            <Box component="span" sx={{ ...sx.headerGradiant }}>
              SOLD
            </Box>
          </Typography>
          <Typography variant="h4" sx={{ mb: 3 }}>
            (from 10,000 total items)
          </Typography>

          <Typography variant="h4">
            Blockchain platform powered by{" "}
            {bold(
              <Box
                component="a"
                href={ethercardUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={sx.link}
              >
                Galaxis
              </Box>
            )}
          </Typography>
        </Box>
        <JoinDiscord />
      </Box>
    </Box>
  );
});

Banner.displayName = "Banner";

export default Banner;
