import { useCallback, useState } from "react"
import { ethers } from "ethers";
import axios from 'axios';
import md5 from "blueimp-md5";
import config from "../config";
import { toast } from "react-toast";
import useWeb3Ctx from "./useWeb3Ctx";
import { usePlutoV2ForgeContractPolygon } from "./useContract";

const redeemTrait = async (params) => {
    return axios({
        method: "post",
        url: `${config.API_URL}api/metadata/redeem`,
        data: params,
    })
};

const submitKycData = async (params) => {
    return axios({
        method: "post",
        url: `${config.API_URL}api/metadata/kyc`,
        data: params,
    }).then((res) => {
        return res.data;
    })
};

const handleApiError = (e) => {
    console.error(e);
    console.error("Error occured!")
    if (e.response && e.response.data && e.response.data.message) {
        toast.error(e.response.data.message);
    } else {
        toast.error(e.message)
    }
};

const handleWalletError = (e) => {
    if (e.error && e.error.message) {
        console.error(e.error.message);
        toast.error(e.error.message);
    } else if (e.message) {
        console.error(e.message);
        toast.error(e.message);
    }
};

const personalSign = async (message, address, ethersProvider) => {
    return ethersProvider
        .send("personal_sign", [message, address.toLowerCase()])
        .catch(handleWalletError);
};

const hashMessage = (kycHash, rewardId, rowId, timestamp) => {
    let hexTimestamp = timestamp.toString(16).padStart(16, "0");
    let padRowId = rowId.toString(16).padStart(16, "0");
    let padRewardId = rewardId.toString(16).padStart(16, "0");
    const message = `${kycHash}${padRewardId}${padRowId}${hexTimestamp}`;
    console.info("messageHash, ", message);
    return ethers.utils.keccak256(message.toString());
};

const hashKycData = (params) => {
    let message = JSON.stringify(params);
    // console.log(message)
    return "0x" + md5(message);
};

const useRedeemKyc = () => {
    const { address, ethersProvider, setChain } = useWeb3Ctx();
    const contract = usePlutoV2ForgeContractPolygon();
    const [loading, setLoading] = useState(false)
    const [txEtherscan, setTxEtherscan] = useState(null);

    const redeemKyc = useCallback(async (params, onSuccess) => {
        if (loading) return;
        setLoading(true);

        params.owner_address = address;

        let rewardId = params.reward_id;
        let formData = {};

        if (rewardId >= 1 && rewardId <= 4) {
            // Merch form params
            formData = {
                reward_id: String(rewardId),
                cruise_type: params.cruise_type || "",
                reward_type: params.reward_type || "",
                wallet_address: params.wallet_address || "",
            }
        } else {
            // Cruise form Params
            formData = {
                reward_id: String(rewardId),
                merch_type: params.merch_type || "",
                tee_size: params.tee_size || "",
                hoodie_size: params.hoodie_size || "",
            }
        }
        formData = {
            ...formData,
            name: params.name || "",
            email: params.email || "",
            phone_number: params.phone_number || "",
            discord_name: params.discord_name || "",
            address: params.address || "",
            city: params.city || "",
            state: params.state || "",
            zip_code: params.zip_code || "",
        }

        // console.log(formData)

        const success = await setChain(config.POLYGON_CHAIN_ID).catch(
            handleWalletError
        );

        if (success) {
            const response = await submitKycData(params).catch(handleApiError);

            if (response) {
                const rowId = response.row;
                const timestamp = response.timestamp;
                const SECONDS_IN_DAY = 86400;
                // Add one day time limit for block to be mined
                const paddedTimestamp = Number(timestamp) + Number(SECONDS_IN_DAY);

                // console.log(rowId);

                const kycHash = hashKycData(formData);
                const messageHash = hashMessage(kycHash, rewardId, rowId, paddedTimestamp);
                const signature = await personalSign(messageHash, address, ethersProvider);

                if (!signature) {
                    setLoading(false);
                    return;
                }
                //Recover the address from signature
                const recoveredAddress = ethers.utils.verifyMessage(
                    ethers.utils.arrayify(messageHash),
                    signature
                );
                if (address.toLowerCase() == recoveredAddress.toLowerCase()) {
                    console.log("Signature is validated");
                } else {
                    toast.error("Failed to validate signature");
                    setLoading(false);
                    return;
                }

                let split = ethers.utils.splitSignature(signature);
                // console.log(
                //     kycHash,
                //     rewardId,
                //     paddedTimestamp,
                //     split.v,
                //     split.r,
                //     split.s
                // )

                // await redeemTrait({
                //     ownerAddress: address,
                //     rewardId: params.reward_id,
                //     kycHash: kycHash,
                //     rowId: rowId,
                //     // txHash: tx.hash,
                //     timestamp: paddedTimestamp,
                //     signature: signature,
                // }).then(() => {
                //     toast.success("Successfully redeemed reward!");
                //     if (onSuccess) {
                //         onSuccess()
                //     }
                // }).catch(handleApiError);

                let gas = await contract.estimateGas
                    .claimRedeemable(
                        kycHash,
                        rewardId,
                        rowId,
                        paddedTimestamp,
                        split.v,
                        split.r,
                        split.s
                    ).catch(handleWalletError);

                if (gas) {
                    await contract
                        .claimRedeemable(
                            kycHash,
                            rewardId,
                            rowId,
                            paddedTimestamp,
                            split.v,
                            split.r,
                            split.s,
                            { gasLimit: gas.mul(12).div(10) }
                        ).then((tx) => {
                            setTxEtherscan(`${config.POLYGON_EXPLORER_URL}tx/${tx.hash}`);
                            return tx.wait().then((receipt) => {
                                console.log("txReceipt: ", receipt);
                                if (receipt && receipt.status === 1) {
                                    return redeemTrait({
                                        ownerAddress: address,
                                        rewardId: params.reward_id,
                                        kycHash: kycHash,
                                        rowId: rowId,
                                        txHash: tx.hash,
                                        timestamp: paddedTimestamp,
                                        signature: signature,
                                    }).then(() => {
                                        toast.success("Successfully redeemed reward!");
                                        if (onSuccess) {
                                            onSuccess()
                                        }
                                    }).catch(handleApiError);
                                } else {
                                    toast.error("Transaction Failed");
                                    setLoading(false);
                                }
                            }).catch(handleWalletError);
                        }).catch(handleWalletError);
                }
            }
        }

        setTxEtherscan(null);
        setLoading(false);
    }, [address, ethersProvider]);

    return { redeemKyc, txEtherscan, loading }
};

export default useRedeemKyc;