import React from 'react';
import { Box, Typography } from '@mui/material';
import Utility from './components/Utility';
import u1 from '../../../../assets/images/utilities/orb1.png';
import u2 from '../../../../assets/images/utilities/orb2.png';

const BP1 = '@media (max-width: 1250px)';
const BP2 = '@media (max-width: 620px)';
const BP3 = '@media (max-width: 1800px)';
const BP4 = '@media (max-width: 1200px)';

const sx = {
	root: {
		pb: '128px',
		[BP3]: {
			pt: '100px',
		},
		[BP1]: {
			pt: '170px',
		},
		[BP4]: {
			pt: '0px',
		},
	},
	title: {
		zIndex: '0',
		fontSize: '20px',
		color: '#FFFFFF',
		fontWeight: 600,
		lineHeight: '34px',
		fontFamily: 'bold-poppins',
	},
	text: {
		zIndex: '0',
		mt: '16px',
		fontSize: '18px',
		color: '#FFFFFF',
		lineHeight: '26px',
		maxWidth: '290px',
		[BP2]: {
			maxWidth: '260px',
			fontSize: '16px',
			lineHeight: '27px',
		},
	},
	utilTitle: {
		fontSize: '54px',
		fontFamily: 'bold-poppins',
		mb: '150px',
		color: '#FFFFFF',
		transition: 'all .3s',
	},
	utilContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		mx: 'auto',
		mt: '56px',
		flexWrap: 'wrap',
		gap: '236px 0px',
	},
};

const Benefits = () => (
	<Box sx={sx.root}>
		<Typography sx={sx.utilTitle} align='center'>Utilities</Typography>
		<Box sx={sx.utilContainer}>
			<Utility icon={u1}>
				<Typography sx={sx.title}>Access utility</Typography>
				<Typography sx={sx.text}>Access to the Pluto Alliance community discord where we make it rain on our verified aliens! Also, merch store will go live shortly after launch. Wear it so everyone can know Pluto is a planet!</Typography>
			</Utility>
			<Utility icon={u2}>
				<Typography sx={sx.title}>Rev share</Typography>
				<Typography sx={sx.text}>50% of all Opensea royalties will be given back to the community, half of which will go to the forged panels! We want to make sure we keep rewarding Pluto Alliance holders unlike any other project.</Typography>
			</Utility>
		</Box>
	</Box>
);

export default Benefits;
