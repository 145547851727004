import React, { useEffect, useState } from "react";
import useWeb3Ctx from "../../hooks/useWeb3Ctx";
import useWallet from "../../hooks/useWallet";
import usePolygonWallet from "../../hooks/usePolygonWallet";
import config from "../../config";
import { Box, Typography, Grid, Button } from "@mui/material";
import { SpinnerDiamond as Spinner } from "spinners-react";
import bgMint2 from "../../assets/images/mint-background-2.png";
import Divider from "../../components/Divider";
import FullscreenModal from "../../components/FullscreenModal";
import ethIcon from "../../assets/images/chains/eth-chain.png"
import polygonIcon from "../../assets/images/chains/polygon-chain.png"

const BP1 = "@media (max-width: 539px)";
const BP2 = "@media (max-width: 719px)";
const BP3 = "@media (max-width: 899px)";
const COLOR_CYAN = "#19A8B4";

const sx = {
  root: {
    height: "100%",
    minHeight: "100vh",
    backgroundColor: "#000",
    backgroundSize: "cover",
    background: `url(${bgMint2})`,
    py: "44px",
    position: "relative",
    transition: "all .3s",
    [BP3]: {
      px: "85px",
    },
    [BP2]: {
      px: "25px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
    mb: 4,
  },
  title: {
    color: "#fff",
  },
  gridContainer: {
    my: 3,
    mx: "auto",
    maxWidth: "80%",
    justifyContent: "center",
  },
  nftContainer: {
    backgroundColor: "transparent",
    boxShadow: "0 0 10px 5px rgba(255,255,255,0.5)",
    width: "100%",
    paddingTop: "117%",
    position: "relative",
    overflow: "hidden",
    transition: "all .3s",
    perspective: "1px",
    mb: 1,
    borderRadius: "10px",

    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.1)",
      borderColor: "transparent",
    },
  },
  nftImg: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  nftText: {
    color: "#fff",
    textAlign: "center",
    fontWeight: 700,
  },
  connectBtn: {
    textTransform: "uppercase",
    fontSize: "0.9rem",
    minWidth: "150px",
    height: "40px",
    margin: "auto",
    backgroundColor: "#000",
    color: "#fff",
    borderRadius: "4px",
    borderColor: "#27DFC7",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "roboto",
    transition: "all .3s",
    "&:focus": {
      backgroundColor: "#27DFC7",
      color: "#fff",
      outlineColor: "#000",
      opacity: 0.8,
    },
    "&:disabled": {
      backgroundColor: "primary.main",
      cursor: "not-allowed",
      opacity: 0.5,
    },
    "&:hover": {
      color: "#fff",
      backgroundColor: "#27DFC7",
      borderColor: "#27DFC7",
    },
    "&:active": {
      outlineColor: "unset",
    },
  },
  chainInfoHolder:{
    position:"absolute",
    top:10,
    left:6,
    zIndex:"3"
  },
  chainInner:{
    height:"32px",
    padding:"6px",
    display:"flex",
    alignItems:"center"
  },
  chainInfoBG:{
    backgroundColor:"#fff",
    borderRadius:"50px",
    "& img": {
      maxHeight:"22px",
      maxWidth: "14px",
      mx:"4px"
    },
    "& span":{
      color:"#000",
      fontSize:"10px",
      display:"none"
    },
    "&:hover": {
      "& span":{
        display:"block",
        transition:"2s all"
      }
    }
  },
};

const Wallet = () => {
  let metadata, isLoading;

  // if (config.DEPLOYED_CHAIN_ID == 1) {
  //   const { metadata: _metadata, isLoading: _loading } = useWallet();
  //   metadata = _metadata;
  //   isLoading = _loading;
  // } else {
  //   const { metadata: _metadata, isLoading: _loading, forgeableOnMainnet = [] } = usePolygonWallet();
  //   metadata = [...(_metadata ? _metadata : []), ...(forgeableOnMainnet ? forgeableOnMainnet : [])];
  //   isLoading = _loading;
  // }

  const { metadata: _metadata, isLoading: _loading, forgeableOnMainnet = [] } = usePolygonWallet();
  metadata = [...(_metadata ? _metadata : []), ...(forgeableOnMainnet ? forgeableOnMainnet : [])];
  isLoading = _loading;

  console.log(metadata, " metadata")
  const { address, handleConnect } = useWeb3Ctx();

  const [isOpen, setOpen] = useState(false);
  const [fullscreenSrc, setFullscreenSrc] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setFullscreenSrc(null);
    }
  }, [isOpen]);

  const setFullscreen = (e) => {
    console.log(e.target.src)
    setOpen(true);
    setFullscreenSrc(e.target.src);
  };
  const setFullscreenFromChainInfo = (event, src) => {
    setOpen(true);
    setFullscreenSrc(src);
    event.stopPropagation();

  };
  const ChainInfo = ({isOnMainnet, src}) =>{
    return <>
    <Box sx={sx.chainInfoHolder} onClick={(event)=>{setFullscreenFromChainInfo(event,src)}}>
      <Box sx={sx.chainInfoBG}>
      {isOnMainnet ? 
      <Box sx={sx.chainInner}><img src={ethIcon}/><Typography variant="text" sx={sx.nftText}>
      Card is on Ethereum chain
    </Typography></Box> :
      <Box sx={sx.chainInner}>
        <img src={polygonIcon}/>
        <Typography variant="text" sx={{...sx.nftText, ...sx.chainText}}>
          Card is on Polygon chain
        </Typography>
      </Box>  
    }
    </Box>
      </Box>
      </>
  }
  return (
    <Box sx={sx.root}>
      <Box sx={sx.content}>
        <Typography variant="heading1" sx={sx.title}>
          Wallet
        </Typography>
        <Divider titleDivider />
       
        {!address && (
          <Box display="flex" flexDirection="column">
            <Typography variant="text" sx={{ my: 4, color: "#fff" }}>
              In order to see your tokens, you need to connect your wallet
            </Typography>
            <Button
              variant="outlined"
              sx={sx.connectBtn}
              onClick={handleConnect}
            >
              Connect Wallet
            </Button>
          </Box>
        )}
      </Box>

      {isLoading && (
        <Box sx={{ textAlign: "center" }}>
          <Spinner color="#19A8B4" secondaryColor="rgba(255,255,255,0.5)" />
        </Box>
      )}

      {address && (
        <>
          {!isLoading && metadata.length == 0 && (
            <Box
              variant="text"
              sx={{ my: 4, color: "#fff", textAlign: "center" }}
            >
              Your wallet is empty.
            </Box>
          )}
          <Grid container sx={sx.gridContainer}>
            {metadata &&
              metadata.map((metadata) => {
                return (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={2}
                    sx={{ p: 2, textAlign: "center" }}
                    key={metadata.id}
                  >
                    <Box sx={sx.nftContainer} onClick={setFullscreen}>
                      <ChainInfo isOnMainnet={metadata.isOnMainnet} src={metadata.image}/>
                      <Box
                        component={
                          metadata.image.includes(".jpg") ||
                          metadata.image.includes(".png")
                            ? "img"
                            : "video"
                        }
                        sx={sx.nftImg}
                        src={metadata.image}
                        autoPlay
                        muted
                        loop
                      />
                    </Box>
                    <Typography variant="text" sx={sx.nftText}>
                      {metadata.name}
                    </Typography>
                  </Grid>
                );
              })}
          </Grid>
        </>
      )}

      <FullscreenModal
        setOpen={setOpen}
        isOpen={isOpen}
        imgSrc={fullscreenSrc}
      />
    </Box>
  );
};

export default Wallet;
