import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useSaleInfo from '../../hooks/useSaleInfo';
import Banner from './components/Banner/Banner';
import About from './components/About/About';
import Benefits from './components/Benefits/Benefits';
import BuyModal from './components/BuyModal';
import BurnMintModal from './components/BurnMint/index';
import Footer from '../../components/Footer';
import Checkout from './components/Checkout';
import { useSelector } from 'react-redux';

const REFRESH_INTERVAL = 60 * 1000;

const Home = () => {
	const [checkoutVisible, setCheckoutVisible] = useState(false);
	const [burnMintVisible, setBurnMintVisible] = useState(false);
	const history = useHistory();

	const { saleEnded } = useSelector((state) => state.info.data);
	const info = useSaleInfo(!saleEnded && REFRESH_INTERVAL);

	useEffect(() => {
		const { hash } = history.location;
		if (hash && document.getElementById(hash.substr(1))) {
			// Check if there is a hash and if an element with that id exists
			document.getElementById(hash.substr(1)).scrollIntoView({ behavior: 'smooth' });
		}
	}, [history.location.hash]); // Fi

	return (
		<>
			<Banner 
				onMintBtnClick={() => setCheckoutVisible(true)} 
				onBurnBtnClick={() => setBurnMintVisible(true)} 
			/>
			<About />
			<Benefits />
			<BurnMintModal visible={burnMintVisible} setVisible={setBurnMintVisible} />
			<Checkout visible={checkoutVisible} setVisible={setCheckoutVisible} mintInfo={{}} />
			<Footer />
		</>
	);
};

export default Home;
