import React from 'react';
import { Box } from '@mui/material';
import SocialButton from './SocialButton';

const sx = {
	root: {
		width: '100%',
		px: '20px',
		pt: '30px',
		pb: '30px',
		backgroundColor: '#000',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: '48px',
	},
	text: {
		maxWidth: '840px',
		fontSize: '16px',
		color: '#FFFFFF',
		textAlign: 'center',
		lineHeight: '28px',
	},
	socialContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		gap: '24px 24px',
		flexWrap: 'wrap',
	},
	emailContainer: {
		height: '40px',
		borderRadius: '20px',
		cursor: 'pointer',
		backgroundColor: '#FFFFFF1A',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		px: '8px',
		gap: '0 4px',
	},
	emailText: {
		fontSize: '14px',
		color: 'background.light',
	},
};

const Footer = () => (
	<Box sx={sx.root}>
		<Box sx={sx.socialContainer}>
			<SocialButton variant='discord' />
			<SocialButton variant='twitter' />
		</Box>
	</Box>
);

export default Footer;
