import { combineReducers } from 'redux';
import info from './info/reducer';
import purchase from './purchase/reducer';
// import contract from './contract/reducer';
import wallet from './wallet/reducer';

export default combineReducers({
	info, 
	purchase, 
	// contract, 
	wallet,
});
