import { Box, Typography, Button } from "@mui/material";
import { getMerchTypeById, rewardMapping } from "../constants/index";

const sx = {
  cardsContainer: {
    width: {
      xs: "90%",
      sm: "80%",
      md: "70%",
      lg: "60%",
    },
    background:
      "transparent linear-gradient(105deg, #00000080 0%, #27DFC733 100%) 0% 0% no-repeat padding-box",
    border: "1px solid #27DFC7",
    borderRadius: "6px",
    p: 2,
    my: 2,
    mx: "auto",
    display: "flex",
    flexDirection: "column",
  },
  cardsHeader: {
    textAlign: "center",
    color: "#fff",
    fontFamily: "bold-poppins",
  },
  headerGradiant: {
    backgroundImage: "linear-gradient(180deg,#27DFC7,#fff)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  btn: {
    minWidth: "210px",
    fontSize: "0.9rem",
    fontWeight: "600",
    transition: "opacity 0.1s linear",
    borderWidth: "2px",
    borderColor: "#27DFC7",
    mt: "10px",
    py: "11px",
    mx: "auto",
    "&:hover": {
      backgroundColor: "#27DFC7",
      borderWidth: "2px !important",
      borderColor: "#27DFC7",
      opacity: 0.9,
    },
    "&:disabled": {
      color: "#fff",
      backgroundColor: "rgba(255,255,255,0.5)",
      cursor: "not-allowed",
    },
  },
};

const ForgeRewardsContainer = ({ rewardType, rewardId, amount, onClick }) => {
  let rewardText;
  if (rewardType == "merch") {
    const merchType = getMerchTypeById(rewardId);
    rewardText = merchType ? merchType.toUpperCase() : "";
  } else {
    rewardText = rewardMapping[rewardId];
  }
  return (
    <Box sx={sx.cardsContainer}>
      <Typography variant="h2" sx={sx.cardsHeader}>
        <Box component="span" sx={sx.headerGradiant}>
          {rewardText.toUpperCase()} {rewardType.toUpperCase()}{" "}
        </Box>
        REWARD
      </Typography>
      <Button
        variant="outlined"
        color="light"
        sx={{ ...sx.btn, ...sx.connectBtn }}
        onClick={onClick}
      >
        SIGN UP
      </Button>
    </Box>
  );
};

export default ForgeRewardsContainer;
