
import useWeb3Ctx from './useWeb3Ctx'
import { useMemo } from 'react'
import { getContract } from '../utils'
import { abi as ZOOM_ABI } from '../abi/Zoom.json'
import { abi as ZOOM2_ABI } from '../abi/Zoom2.json'
import { abi as EC_ABI } from '../abi/EtherCards.json'
import { abi as PLUTO_V2_SALE_ABI } from '../abi/SkeletonCrew.json'
import { abi as PLUTO_V2_ABI } from '../abi/PlutoV2.json'
import { abi as PLUTO_ABI } from '../abi/Pluto.json'
import { abi as PLUTO_COMMUNITY_ABI } from '../abi/PlutoCommunity.json'
import { abi as PLUTO_V2_FORGE_ABI } from '../abi/PlutoV2Forge.json'
import { abi as POLYGON_ZOOM2_ABI } from '../abi/PolygonZoom2.json'
import { abi as POLYGON_PLUTO_V2_ABI } from '../abi/PlutoV2Polygon.json'

import {
    ZOOM_2_ADDRESSES,
    ZOOM_ADDRESSES,
    EC_ADDRESSES,
    PLUTO_ADDRESSES,
    PLUTO_V2_SALE_ADDRESSES,
    PLUTO_COMMUNITY_ADDRESSES,
    PLUTO_V2_ADDRESSES,
    PLUTO_V2_FORGE_ADDRESSES
} from '../abi/constants/addresses'

import config from "../config";
import { Contract, providers } from 'ethers'
const { POLYGON_CHAIN_ID } = config;

// returns null on errors
export function useContract(
    addressOrAddressMap,
    ABI,
    withSignerIfPossible = true,
    contractChainId) {
    const {
        defaultProvider: deployedLibrary,
        ethersProvider: library,
        address: account,
        chainId,
        isCorrectNetwork,
        defaultChainId,
        getProvider } = useWeb3Ctx();

    return useMemo(() => {
       
        if (!isCorrectNetwork && (!deployedLibrary || !defaultChainId)) return null
        if (isCorrectNetwork && (!addressOrAddressMap || !ABI || !library || !chainId)) return null
        let address
        if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
        // Check if contractChainId is present then supply the appropriate provider for that contract
        // else check if currentNetwork is different from app deployed network
        else address = addressOrAddressMap[contractChainId ? contractChainId : isCorrectNetwork ? chainId : defaultChainId]
        if (!address) return null
        let provider = getProvider(contractChainId)

        try {
            return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
        } catch (error) {
            console.error('Failed to get contract', error)
            return null
        }
    }, [addressOrAddressMap, ABI, library, chainId, withSignerIfPossible, account, deployedLibrary, isCorrectNetwork])
}

export function useZoomContract() {
    return useContract(ZOOM_ADDRESSES, ZOOM_ABI, false)
}
export function useZoom2Contract() {
    return useContract(ZOOM_2_ADDRESSES, ZOOM2_ABI, false)
}
export function usePolygonZoom2Contract() {
    return useContract(ZOOM_2_ADDRESSES, POLYGON_ZOOM2_ABI, false, config.POLYGON_CHAIN_ID)
}
export function useEcContract() {
    return useContract(EC_ADDRESSES, EC_ABI, false)
}
export function usePlutoContract() {
    return useContract(PLUTO_ADDRESSES, PLUTO_ABI, true)
}
export function usePlutoV2Contract() {
    return useContract(PLUTO_V2_ADDRESSES, PLUTO_V2_ABI, false)
}
export function usePlutoV2PolygonContract() {
    return useContract(PLUTO_V2_ADDRESSES, POLYGON_PLUTO_V2_ABI, false, config.POLYGON_CHAIN_ID)
}
export function useSaleContract() {
    return useContract(PLUTO_V2_SALE_ADDRESSES, PLUTO_V2_SALE_ABI, true)
}
export function useCommunityContract() {
    return useContract(PLUTO_COMMUNITY_ADDRESSES, PLUTO_COMMUNITY_ABI, true)
}
export function usePlutoV2ForgeContractPolygon() {
    return useContract(PLUTO_V2_FORGE_ADDRESSES, PLUTO_V2_FORGE_ABI, true, POLYGON_CHAIN_ID)
}
export function usePlutoV2ContractPolygon() {
    return useContract(PLUTO_V2_FORGE_ADDRESSES, PLUTO_V2_ABI, false, POLYGON_CHAIN_ID)
}
export function usePlutoV2ContractMainnet() {
    return useContract(PLUTO_V2_ADDRESSES, PLUTO_V2_ABI, false, 1)
}
export function useZoom2ContractMainnet() {
    return useContract(ZOOM_2_ADDRESSES, ZOOM2_ABI, false, 1)
}