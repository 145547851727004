import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box } from "@mui/material";

const sx = {
  draggable: {
    width: "100%",
    position: "relative",
    p: 1,
    maxWidth: "150px",
    minWidth: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    
  },
};

export function SortableItem({ id, style, children }) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: id });

  const _style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "pointer",
    opacity: isDragging ? 0.7 : 1,
  };

  return (
    <Box
      sx={sx.draggable}
      ref={setNodeRef}
      style={{ ..._style, ...style }}
      {...listeners}
      {...attributes}
    >
      {children}
    </Box>
  );
}
