
import { useCallback, useEffect, useMemo } from "react"
import { useEcContract } from "./useContract";
import useWeb3Ctx from "./useWeb3Ctx";
import { useSelector, useDispatch } from "react-redux";
import { loadEcWallet } from "../store/wallet/actions";

export default function useEcWallet() {
    const { address } = useWeb3Ctx();
    const ecContract = useEcContract();
    const dispatch = useDispatch();
    const { hasEcToken } = useSelector((state) => state.wallet.ec);

    const fetchWallet = useCallback(() => {
        if (ecContract && address) {
            (async () => {
                try {
                    await dispatch(loadEcWallet(ecContract, address))();
                } catch (error) {
                    console.log(error);
                }
            })();
        }
    }, [ecContract, dispatch, address]);


    useEffect(() => {
        if (address) {
            fetchWallet();
        }
    }, [address])

    return useMemo(() => hasEcToken, [hasEcToken]);
}