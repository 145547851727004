import React, { useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '../../../assets/images/close-icon-white.svg';

const sx = {
	root: {
		zIndex: 1000,
	},
	details: {
		position: 'absolute',
		maxHeight: '100%',
		height: '100%',
		top: 0,
		right: 0,
		left: 0,
		transformOrigin: 'top right',
		zIndex: 1001,
		overflow: 'hidden',
		transform: 'scale(0)',
		transition: 'all 0.4s ease-in-out',
		opacity: 0,
	},
	detailsContent: {
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.8)',
		py: '10%',
		px: '10px',
		fontSize: '1rem',
		overflow: 'hidden',
		scrollbarWidth: 'thin',
		scrollbarColor: 'rgba(0, 0, 0, 0.3) transparent',
		borderRadius: '4px',
		textAlign: 'center',
		'& p': {
			textShadow: '0px 0px 10px rgb(0 0 0 / 50%)',
			marginTop: '10px',
			color: '#ccc',
			fontSize: '1.1em',
			width: '100%',
		},
		'& h3': {
			mt: '30px',
			// color: '#F00',
			color: '#fff',
			fontSize: '1.4em',
			textTransform: 'capitalize',
		},
	},
	traitBtn: {
		position: 'absolute',
		background: '#000',
		borderRadius: '4px',
		fontSize: '1rem',
		p: 0,
		height: '2rem',
		width: '2rem',
		fontWeight: 800,
		color: '#fff !important',
		top: '10px',
		right: '10px',
		zIndex: 1,
		'& :hover': {
			background: '#000',
			borderColor: '#ccdbd8',
			opacity: 0.8,
			cursor: 'pointer',
		},
		'& img': {
			height: '70%',
			width: '70%',
			margin: 'auto',
			position: 'absolute',
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
		},
	},
	closeBtn: {
		position: 'fixed',
		height: '16px',
		width: '16px',
		top: '4%',
		right: '4%',
		fontWeight: 600,
		fontSize: '1rem',
		lineHeight: '1rem',
		color: 'white',
		transition: 'color 0.3s ease-in-out',
		cursor: 'pointer',
		'& img': {
			width: '16px',
			height: '16px',
			filter: 'none',
			top: 0,
			right: 0,
			color: '#FFF',
		},
	},
};

const TraitsPopup = ({ traits }) => {
	const [showPopup, setShowPopup] = useState(null);

	return (
		<>
			{traits && traits.length > 0 && (
				<Box sx={sx.root}>
					{traits.map((trait, index) => {
						const topOffset = 20;
						const traitOffset = index * 40;
						const btnStyle = { ...sx.traitBtn, top: `${topOffset + traitOffset}px`, display: showPopup ? 'none' : 'block' };
						return (
							<Box key={trait.id} sx={btnStyle} onClick={() => setShowPopup(trait)}>
								<img src={trait.icon} alt={`Trait ${trait.name}`} />
							</Box>
						);
					})}
					<Box sx={{ ...sx.details, ...(showPopup ? { transform: 'scale(1)', opacity: 1 } : {}) }}>
						<Box sx={sx.closeBtn} onClick={() => setShowPopup(null)}>
							<img src={CloseIcon} alt='Close' />
						</Box>
						<Box sx={sx.detailsContent}>
							{showPopup && (
								<>
									<img height={30} src={showPopup.icon} alt={`Trait ${showPopup.name}`} />
									<h3>{showPopup.name}</h3>
									<p>{showPopup.description}</p>
								</>
							)}
						</Box>
					</Box>
				</Box>
			)}
		</>
	);
};

TraitsPopup.propTypes = {
	traits: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default TraitsPopup;
