import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ethers } from "ethers";
import { toast } from "react-toast";
import { Box, Modal, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { SpinnerRoundOutlined as Spinner } from "spinners-react";
import { useSaleContract } from "../../../hooks/useContract";
import useEcWallet from "../../../hooks/useEcWallet";
import Divider from "../../../components/Divider";
import MintQuantity from "./MintQuantity";
import config from "../../../config";
import close from "../../../assets/images/close-icon-white.svg";
import background from "../../../assets/images/background.png";

const BP1 = "@media (max-width: 899px)";
const BP2 = "@media (max-width: 719px)";
const COLOR_CYAN = "#5deffb";

const sx = {
  root: {
    height: "100%",
    minHeight: "100%",
    backgroundImage: `url(${background})`,
    overflow: "auto",
    pt: "60px",
    px: "125px",
    // position: "relative",
    transition: "all .3s",
    [BP1]: {
      px: "85px",
    },
    [BP2]: {
      px: "25px",
    },
  },
  title: {
    color: "#fff",
    mt: "20px",
    textAlign: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
  },
  textContainer: {
    position: "absolute",
    mt: "8px",
    width: "100%",
    height: "26px",
    left: 0,
  },
  closeBtn: {
    width: "24px",
    height: "24px",
    position: "fixed",
    top: "80px",
    right: "10%",
    cursor: "pointer",
    pointerEvents: "auto",
    transition: "all .3s",
    [BP1]: {
      top: "20px",
    },
  },
  url: {
    textDecoration: "none",
    color: "#19a8b4",
    fontWeight: "700",
  },
  darkOverlay: {
    zIndex: 100,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.8)",
    pointerEvents:'none',
  },
};

const Checkout = ({ visible, setVisible }) => {

  const [approveInProgress, setApproveInProgress] = useState(false);
  const [txInProgress, setTxInProgress] = useState(false);
  const [txEtherScan, setTxEtherScan] = useState(null);
  
  const { price, discountPrice } = useSelector((state) => state.info.data);
  const hasEcToken = useEcWallet();  
  const history = useHistory();
  const saleContract = useSaleContract();

  const onClickMintWithEC = async (quantity, totalPrice) => {
    setApproveInProgress(true);
    console.log(quantity, totalPrice);
    console.log("start mint with EC");

    const payingAmount = ethers.utils.parseEther(totalPrice.toString());

    await saleContract
      .mintDiscountPresaleWithEtherCards(quantity, { value: payingAmount })
      .then((tx) => {
        setApproveInProgress(false);
        setTxInProgress(true);
        console.log(`${config.ETHERSCAN_URL}tx/${tx.hash}`);
        setTxEtherScan(`${config.ETHERSCAN_URL}tx/${tx.hash}`);
        return tx.wait().then((receipt) => {
          console.log("txReceipt: ", receipt);
          if (receipt && receipt.status === 1) {
            toast.success("Successfully Bought NFT");
            history.replace({ ...history.location, state: null });
            history.push({
              pathname: "/wallet",
            });
            setTxEtherScan(null);
          } else {
            toast.error("Transaction Failed");
            setTxInProgress(false);
            setTxEtherScan(null);
          }
        });
      })
      .catch(handleError);

    setTxInProgress(false);
    setApproveInProgress(false);
  };

  const onClickMintSale = async (quantity, totalPrice) => {
    setApproveInProgress(true);
    console.log(quantity, totalPrice);
    console.log("start mint with EC");

    const payingAmount = ethers.utils.parseEther(totalPrice.toString());

    await saleContract
      .mint(quantity, { value: payingAmount })
      .then((tx) => {
        setApproveInProgress(false);
        setTxInProgress(true);
        console.log(`${config.ETHERSCAN_URL}tx/${tx.hash}`);
        setTxEtherScan(`${config.ETHERSCAN_URL}tx/${tx.hash}`);
        return tx.wait().then((receipt) => {
          console.log("txReceipt: ", receipt);
          if (receipt && receipt.status === 1) {
            toast.success("Successfully Bought NFT");
            history.replace({ ...history.location, state: null });
            history.push({
              pathname: "/wallet",
            });
            setTxEtherScan(null);
          } else {
            toast.error("Transaction Failed");
            setTxInProgress(false);
            setTxEtherScan(null);
          }
        });
      })
      .catch(handleError);

    setTxInProgress(false);
    setApproveInProgress(false);
  };

  const handleError = (e) => {
    if (e.error && e.error.message) {
      toast.error(e.error.message);
    } else if (e.message) {
      toast.error(e.message);
    } else if (e.reason) {
      toast.error(e.reason);
    }
  };

  return (
    <Modal
      open={visible}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && !txInProgress && !approveInProgress) {
          setVisible(false);
        }
      }}
    >
      <Box sx={sx.root}>
        <Box sx={sx.content}>
          {!txInProgress ? (
            <>
              <Typography variant="heading2" sx={sx.title}>
                Checkout Page
              </Typography>
              <Divider titleDivider />
              <Typography variant="text" sx={{ my: 4, color: "#fff" }}>
                Please select the number of NFTs you want to mint.
              </Typography>
              <MintQuantity
                title="Public Sale Mint"
                price={Number(price)}
                maxAmount={10}
                onClickMint={onClickMintSale}
              />
              {hasEcToken && (
                <MintQuantity
                  title="Ether Cards Mint 10% Discount"
                  price={Number(discountPrice)}
                  maxAmount={10}
                  onClickMint={onClickMintWithEC}
                />
              )}
            </>
          ) : (
            <>
              <Typography variant="heading1" sx={sx.title}>
                Transaction In Progress
              </Typography>
              <Divider titleDivider />
              <Typography variant="text" sx={{ my: 4, color: "#fff" }}>
                Please wait while your transaction is being processed. <br />
                {txEtherScan && (
                  <>
                    You can check the transaction status on{" "}
                    <Box
                      component="a"
                      href={txEtherScan}
                      sx={sx.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Etherscan
                    </Box>
                    .
                  </>
                )}
              </Typography>

              <Spinner
                color={COLOR_CYAN}
                style={{ marginLeft: "auto", marginRight: "auto" }}
              />
            </>
          )}
        </Box>

        {approveInProgress && <Box sx={sx.darkOverlay} />}

        {!txInProgress && (
          <Box
            sx={sx.closeBtn}
            onClick={() => !txInProgress && setVisible(false)}
          >
            <img src={close} style={{ width: "100%" }} alt="Close" />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

/* eslint-disable react/forbid-prop-types */
Checkout.propTypes = {
  mintInfo: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.any.isRequired,
};
export default Checkout;
