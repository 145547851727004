import { SET_INFO } from './actions';
import { SET_IS_LOADING } from './actions';

const INITIAL_STATE = {
	data: {
		status: 'presale',
		items: 0,
		sold: 0,
		price: 0,
		discountPrice: 0,
		saleStarted: false,
		saleEnded: false,
		presaleStarted: false,
		presaleEnded: false,
		burnActive: false,
	},
	isLoaded: false,
	isLoading: false,
};

const info = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_INFO: {
			return { ...state, data: action.payload, isLoaded: true, isLoading: false };
		}
		case SET_IS_LOADING: {
			return { ...state, isLoading: action.isLoading };
		}
		default: return state;
	}
};

export default info;
