import React from "react";
import { Box, ThemeProvider } from "@mui/material";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from 'react-toast';
import routes from "./navigation/routes";
import Web3Manager from "./components/Web3Manager/Web3Manager";
import theme from "./theme";
import background from "./assets/images/background.png";
import Header from "./components/Header";

const sx = {
  root: {
    position: "relative",
    height: "100%",
    minHeight: "100vh",
    backgroundColor: "#000",
  },
  loaderContainer: {
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  outContainer: {
    maxWidth: "1920px",
    margin: "0 auto",
  },
  pageRoot: {
    height: "100%",
    width: "100%",
    pt: "80px",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center 700px",
  },
};

const App = () => {
  return (
    <Web3Manager>
      <ThemeProvider theme={theme}>
        <Box sx={sx.root}>
          <Router basename='/'>
            <Box sx={sx.outContainer}>
              <Header />
              <Box sx={sx.pageRoot}>
                <Switch>
                  {routes.map(({ path, component, exact }) => (
                    <Route
                      key={path}
                      path={path}
                      component={component}
                      exact={exact}
                    />
                  ))}
                </Switch>
                <ToastContainer delay={4000} position='bottom-right' />
              </Box>
            </Box>
          </Router>
        </Box>
      </ThemeProvider>
    </Web3Manager>
  );
};

export default App;
