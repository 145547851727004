const INITIAL_STATE = {
	inProgress: false,
};

const purchase = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		default: return state;
	}
};

export default purchase;
