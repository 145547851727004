import { Box, Typography } from "@mui/material";
import { SpinnerDiamond as Spinner } from "spinners-react";

const sx = {
  darkOverlay: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    zIndex: 100,
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.9)",
  },
  url: {
    textDecoration: "none",
    color: "#19a8b4",
    fontWeight: "700",
  },
  title: {
    color: "#fff",
    fontFamily: "bold-poppins",
    fontSize: {
      xs: "26px",
      md: "34px",
      lg: "48px",
    },
    lineHeight: {
      xs: "30px",
      md: "40px",
      lg: "50px",
    },
  },
};

const EtherscanModal = ({ txEtherScan }) => {
  return (
    <Box sx={sx.darkOverlay}>
      <Typography variant="heading1" sx={sx.title}>
        Transaction In Progress
      </Typography>
      <Typography variant="text" sx={{ my: 4, color: "#fff" }}>
        Please wait while your transaction is being processed. <br />
        {txEtherScan && (
          <>
            You can check the transaction status on{" "}
            <Box
              component="a"
              href={txEtherScan}
              sx={sx.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Blockchain Explorer
            </Box>
            .
          </>
        )}
      </Typography>

      <Spinner color="#19A8B4" secondaryColor="rgba(255,255,255,0.5)" />
    </Box>
  );
};

export default EtherscanModal;
