import Card from '../views/Card/Card';
import Home from '../views/Home/Home';
import Wallet from '../views/Wallet/Wallet';
import Forge from '../views/Forge/Forge';

const routes = [
	{
		path: '/',
		component: Home,
		exact: true,
	},
	{
		path: '/card/:tokenId',
		component: Card,
		exact: true,
	}, {
		path: '/wallet',
		component: Wallet,
		exact: true,
	}, {
		path: '/forge',
		component: Forge,
		exact: true,
	},
];

export default routes;
