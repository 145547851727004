import { SET_CARDS, SET_PLUTO, SET_EC, SET_LOADING, SET_CARDS_POLYGON } from './actions';

export const CARDS = "cards";
export const PLUTOS = "plutos";
export const EC = "ec";
export const CARDS_POLYGON = "cards_polygon";

const INITIAL_STATE = {
	[CARDS]: {
		metadata: [],
		revealed: false,
		isLoaded: false,
		isLoading: false
	},
	[PLUTOS]: {
		metadata: [],
		isLoaded: false,
		isLoading: false
	},
	[EC]: {
		hasEcToken: false,
		isLoaded: false,
		isLoading: false
	},
	[CARDS_POLYGON]: {
		metadata: [],
		forgeable: [],
		isLoaded: false,
		isLoading: false
	}
};

const wallet = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_CARDS_POLYGON: {
			return { ...state, [CARDS_POLYGON]: { ...action.payload, isLoaded: true, isLoading: false } };
		}
		case SET_CARDS: {
			return { ...state, [CARDS]: { ...action.payload, isLoaded: true, isLoading: false } };
		}
		case SET_PLUTO: {
			return { ...state, [PLUTOS]: { metadata: action.payload, isLoaded: true, isLoading: false } };
		}
		case SET_EC: {
			return { ...state, [EC]: { hasEcToken: action.payload, isLoaded: true, isLoading: false } };
		}
		case SET_LOADING: {
			if (action.name) {
				return { ...state, [action.name]: { ...state[action.name], isLoading: true } };
			}
		}
		default: return state;
	}
};

export default wallet;
