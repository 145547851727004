
import { useCallback, useEffect, useMemo } from "react"
import { useSaleContract, usePlutoV2Contract, useCommunityContract } from "./useContract";
import useWeb3Ctx from "./useWeb3Ctx";
import { useSelector, useDispatch } from "react-redux";
import { setInfo } from "../store/info/actions";
import useInterval from "./useInterval";

export default function useSaleInfo(refreshInterval) {
    const { address } = useWeb3Ctx()
    const saleContract = useSaleContract();
    const tokenContract = usePlutoV2Contract();
    const communityContract = useCommunityContract();
    const dispatch = useDispatch();
    const info = useSelector((state) => state.info);

    const fetchInfo = useCallback(() => {
        if (saleContract && tokenContract && communityContract) {
            (async () => {
                console.log('fetching Info')
                try {
                    await dispatch(setInfo(saleContract, tokenContract, communityContract, address || "0x0000000000000000000000000000000000000000"))();
                } catch (error) {
                    console.log(error);
                }
            })();
        }
    }, [saleContract, tokenContract, communityContract, dispatch, address]);

    useInterval(fetchInfo, refreshInterval || null, false)

    useEffect(() => {
        fetchInfo();
    }, [])

    useEffect(() => {
        if (address) {
            fetchInfo();
        }
    }, [address])

    return useMemo(() => {
        console.log(info)
        return info
    }, [info]);
}
