
import { useCallback, useEffect, useMemo } from "react"
import { usePlutoContract, useZoom2Contract } from "./useContract";
import useWeb3Ctx from "./useWeb3Ctx";
import { useSelector, useDispatch } from "react-redux";
import { loadPlutoWallet } from "../store/wallet/actions";

export default function usePlutoWallet() {
    const { address } = useWeb3Ctx();
    const plutoContract = usePlutoContract();
    const zoomContract = useZoom2Contract();
    const dispatch = useDispatch();
    const tokens = useSelector((state) => state.wallet.plutos);

    const fetchWallet = useCallback(() => {
        if (plutoContract && address) {
            (async () => {
                try {
                    await dispatch(loadPlutoWallet(plutoContract, zoomContract, address))();
                } catch (error) {
                    console.log(error);
                }
            })();
        }
    }, [plutoContract, dispatch, address]);


    useEffect(() => {
        if (address) {
            fetchWallet();
        }
    }, [address])

    return useMemo(() => address ? tokens : [], [tokens, address]);
}