import React, { useState, useRef } from 'react';
import { Box, Grow } from '@mui/material';
import VisibilitySensor from 'react-visibility-sensor';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import PropTypes from 'prop-types';

const BP2 = '@media (max-width: 1200px)';
const BP3 = '@media (max-width: 1350px)';
const BP4 = '@media (max-width: 1500px)';
const BP5 = '@media (max-width: 1800px)';

const sx = {
	root: {
		width: '100%',
		maxWidth: '1920px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: '40px 132px',
		[BP3]: {
			gap: '100px 300px',
		},
		[BP4]: {
			gap: '100px 50px',
		},
		[BP2]: {
			flexDirection: 'column',
			gap: '200px 50px',
			pl: '0',
			pr: '0px',
			height: '100%',
			mt: '20px',
		},
	},
	imageContainer: {
		mr: '20px',
        transition: "transform 200ms linear",
	},

};

const AboutItem = ({ leftContent, children, img }) => {
	const [visible, setVisible] = useState(true)
	const [scale, setScale] = useState(1);
	const ref = useRef(null);

	const onVisible = (isVisible) => {
		if(!visible && isVisible) {
			setVisible(true)
		}
	}

	useScrollPosition(({ prevPos, currPos }) => {
		if(visible) {
			const { top } = ref.current.getBoundingClientRect()
			const offset = -300;
			const maxScale = 1.2;
			const minScale = 1;
			if(top < 0 && top > offset) {
				const scale = Math.abs(top) / Math.abs(offset) * (maxScale-minScale) + minScale ;
				setScale(scale)
			} else if (top >= 0) {
				setScale(minScale)
			} 
		}
	  })

	return (
		<VisibilitySensor onChange={onVisible}>
			<Box sx={{
				...sx.root,
				...(leftContent ? {
					flexDirection: 'row-reverse',
					justifyContent: 'flex-end',
					[BP2]: {
						pl: '0px',
						flexDirection: 'column',
					},
					gap: '218px',
					[BP4]: { gap: '50px' },
					pl: '140px',
				} : {
					mt: '-130px',
					pr: '140px',
					alignItems: 'flex-start',
					[BP4]: { gap: '4px' },
					height: '550px',
					[BP5]: { gap: '0px' },
				}),
			}}
			ref={ref}
			>
				<Box sx={{ ...sx.imageContainer, ...{ transform:`scale(${scale})` }}}>
						<Grow in={visible} timeout={{ enter: 500, exit: 500 }}>			
							<img src={img} alt='Utility' style={{ maxWidth: '100%' }} />
						</Grow>
				</Box>
				<Box sx={{
					textAlign: leftContent ? 'left' : 'right', maxWidth: leftContent ? '790px' : '859px', marginLeft: leftContent ? '' : 'auto', marginRight: leftContent ? '' : 'auto',
				}}
				>
					{children}
				</Box>
			</Box>
		</VisibilitySensor>
)};

/* eslint-disable react/forbid-prop-types */
AboutItem.propTypes = {
	leftContent: PropTypes.bool,
	children: PropTypes.any.isRequired,
	img: PropTypes.string.isRequired,
};

AboutItem.defaultProps = {
	leftContent: false,
};

export default AboutItem;
