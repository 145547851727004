import { Zoom } from 'zoom-next';
import { getTokenUri } from '.';

export const zoomFetchTokenUris = async (contract, zoom2, address) => {
    const nt = await contract.balanceOf(address);
    const ZoomLibraryInstance = new Zoom({ use_reference_calls: true });
    if (nt > 0) {
        const calls = [];
        for (let i = 0; i < nt; i += 1) {
            const tId = ZoomLibraryInstance.addMappingCountCall(
                contract,
                ['tokenOfOwnerByIndex', [address, i]],
                'tokenOfOwnerByIndex(address,uint256) returns (uint256)',
                [{ contract: contract, mapAndParams: ['tokenURI(uint256)', [i]] }],
            );
            calls.push(tId);

            const tUri = ZoomLibraryInstance.addType5Call(
                contract,
                ['tokenURI(uint256)', [i]],
                'tokenURI(uint256) returns (string)',
            );
            calls.push(tUri);
        }
        const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();
        const combinedResult = await zoom2.combine(ZoomQueryBinary);
        ZoomLibraryInstance.resultsToCache(combinedResult, ZoomQueryBinary);
        const tokenIds = [];
        for (let i = 0; i < nt * 2; i += 2) {
            const id = ZoomLibraryInstance.decodeCall(calls[i]).toString();
            const tokenURI = ZoomLibraryInstance.decodeCall(calls[i + 1]).toString();
            tokenIds.push({ id, tokenURI });
        }

        // console.log(tokenIds)

        const newMetadata = [];

        if (tokenIds.length > 0) {
            const promises = [];
            for (var i = 0; i < tokenIds.length; i++) {
                const { id, tokenURI } = tokenIds[i];
                promises.push(new Promise(async (resolve) => {
                    const metadata = await getTokenUri(id, tokenURI);
                    newMetadata.push(metadata);
                    resolve();
                }))
            }

            console.time(`fetching tokenUri`)
            await Promise.all(promises)
            console.timeEnd(`fetching tokenUri`)
        }

        return newMetadata.sort((a, b) => {
            return Number(a.tokenId) - Number(b.tokenId)
        })
    }
}


export const zoomFetchTokenUrisWithIds = async (zoom2, contract, tokenIds) => {
    const ZoomLibraryInstance = new Zoom();
    const calls = [];

    for (let i = 0; i < tokenIds.length; i++) {
        // console.log(tokenIds[i])
        const tokenUriCall = ZoomLibraryInstance.addCall(
            // the contract we're calling
            contract,
            ['tokenURI(uint256)', [tokenIds[i]]],
            'tokenURI(uint256) returns (string)',
        );
        calls.push(tokenUriCall)
    }

    // Prepare the binary call
    const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();
    console.time(`zoomCall_tokenURI_${contract.address}`);
    const combinedResult = await zoom2.combine(ZoomQueryBinary);
    console.timeEnd(`zoomCall_tokenURI_${contract.address}`);
    ZoomLibraryInstance.resultsToCache(combinedResult, ZoomQueryBinary);
    const tokenURIs = [];
    for (let i = 0; i < tokenIds.length; i++) {
        const tokenURI = ZoomLibraryInstance.decodeCall(calls[i]).toString();
        const id = tokenIds[i];
        tokenURIs.push({ id, tokenURI });
    }

    const newMetadata = [];

    if (tokenURIs.length > 0) {
        const promises = [];
        for (var i = 0; i < tokenURIs.length; i++) {
            const { id, tokenURI } = tokenURIs[i];
            promises.push(new Promise(async (resolve) => {
                const metadata = await getTokenUri(id, tokenURI);
                newMetadata.push(metadata);
                resolve();
            }))
        }

        console.time(`fetching tokenUri`)
        await Promise.all(promises)
        console.timeEnd(`fetching tokenUri`)
    }

    return newMetadata.sort((a, b) => {
        return Number(a.tokenId) - Number(b.tokenId)
    })
}