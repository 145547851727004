import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const BP1 = '@media (max-width: 1240px)';
const BP2 = '@media (max-width: 570px)';

const sx = {
	root: {
		width: '100%',
		position: 'relative',
		maxWidth: '600px',
		px: '20px',
		pt: '24px',
		pb: '48px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		[BP1]: {
			maxWidth: '580px',
		},
	},
	iconContainer: {
		zIndex: '0',
		position: 'absolute',
		mt: '-160px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		[BP2]: {
			position: 'relative',
		},
	},
};

const Utility = ({ icon, children }) => (
	<Box sx={sx.root}>
		<Box sx={sx.iconContainer}>
			<img src={icon} style={{ maxHeight: '100%', maxWidth: '100%' }} alt='Utility' />
		</Box>
		{children}
	</Box>
);

/* eslint-disable react/forbid-prop-types */
Utility.propTypes = {
	icon: PropTypes.string.isRequired,
	children: PropTypes.any.isRequired,
};

export default Utility;
