import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { getCardMeta } from '../../store/meta/actions';
import TraitsPopup from './componenets/TraitsPopup';

const sx = {
	root: {
		width: '100%',
		pt: '135%',
		position: 'relative',
		fontSize: '1rem',
		borderRadius: '20px',
		overflow: 'hidden',
		transition: 'opacity .3s ease-out',
	},
	imgContainer: {
		position: 'absolute',
		top: 0,
		width: '100%',
		'& img': {
			width: '100%',
		},
	},
};

const EmbeddedCard = ({ tokenId }) => {
	const containerRef = useRef();
	const [meta, setMeta] = useState(null);
	const [imageUrl, setImageUrl] = useState(null);
	const [traits, setTraits] = useState(null);

	const dispatch = useDispatch();
	useEffect(() => {
		(async () => {
			try {
				const k = await dispatch(getCardMeta(tokenId))();
				if (k) {
					setMeta(k);
					setImageUrl(k.image);
					setTraits(k.utilities);
				}
			} catch (error) {
				console.log(error);
			}
		})();
	}, [dispatch, tokenId]);

	return (
		<>
			{meta ? (
				<Box ref={containerRef} sx={sx.root}>
					<TraitsPopup traits={traits} />
					<Box sx={sx.imgContainer}><img src={imageUrl} alt='nft-media' /></Box>
				</Box>
			) : <p style={{ color: '#fff', fontSize: '50px' }}>Inexistent token or metadata</p>}
		</>
	);
};

EmbeddedCard.propTypes = {
	tokenId: PropTypes.number.isRequired,
};

export default EmbeddedCard;
