import { SupportedChainId } from './chain'

export const ZOOM_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0x3D4F573904B98066887332EdeF1b3f9b155e8080",
    [SupportedChainId.RINKEBY]: "0xC878B3C422BeECB879dE0a2bea01D30C88F0ccdc"
}

export const ZOOM_2_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0x7cdF091AF6a9ED75E3192500d3e5BB0f63e22Dea",
    [SupportedChainId.RINKEBY]: "0x491592F30D9a3d1887F486eA2A3c72ad82fAcF4D",
    [SupportedChainId.POLYGON]: "0x40faA8d164973Ab39F3cBf6e3876a3130F9c6C77",
    [SupportedChainId.MUMBAI]: "0xcD518b70Cafe0Acea78D3Dc2E4c8af082b6de0Ff",
    [SupportedChainId.GOERLI]: "0xebC7d793d062371C11cB802e7D49eEAA0c30EB06",
}

export const EC_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0x97CA7FE0b0288f5EB85F386FeD876618FB9b8Ab8",
    [SupportedChainId.RINKEBY]: "0xAAD4475343f5150E33d6194270f04e7e5968A2f8"
}

export const PLUTO_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0xdfe3ac769b2d8e382cb86143e0b0b497e1ed5447",
    // [SupportedChainId.RINKEBY]: "0x65b6bc6461c3e11a36713b1c6d15c5b06777bfb8",
    [SupportedChainId.RINKEBY]: "0x032F60Fa3A710E6125112B62Cd7f46B4cBC9C957"
}

export const PLUTO_V2_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0x3F691327267993cFE4842CA1364A52DfE6190Ec1",
    [SupportedChainId.RINKEBY]: "0x2403bdbA886854288C8B150cdFbe018FFCcb8FEd",
    [SupportedChainId.POLYGON]: "0x3b8a54213a898ce4f04180155c0631e105368913",
    [SupportedChainId.MUMBAI]: "0xe877fec1096f7bf084632507e3da7935dd39970f",
    [SupportedChainId.GOERLI]: "0x368d4df97080ad75c016700ae9d82552cde7c9a6",
}

export const PLUTO_V2_FORGE_ADDRESSES = {
    [SupportedChainId.POLYGON]: "0x76361ba1e6d3c642dd0688359345ec9dd7a98c5c",
    [SupportedChainId.MUMBAI]: "0x596506d3af6523f76a257bc500b390b4c9e7d343",
}

export const PLUTO_V2_SALE_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0xD656aA19BfaA1A97Ba614AE8BCBa5E3D3FAc1064",
    [SupportedChainId.RINKEBY]: "0xe42642632750C9Efe68fd9Ff390D7bDAE6e4c04c",
}

export const PLUTO_COMMUNITY_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0xB5671b7895617B232709Dd4B5c6996fE3dbC83df",
    [SupportedChainId.RINKEBY]: "0x66C804dF0392Bca0339cECc52B9DaBdE749D5a8B",
}