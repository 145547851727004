import React, { useState } from "react";
import { Box, Button, Collapse } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ECAddress } from "ec-commons";
import PropTypes from "prop-types";
import SocialButton from "./SocialButton";
import logo from "../assets/images/logo.png";
import menuIcon from "../assets/images/menu_icon.svg";
import collapseIcon from "../assets/images/collapse_icon.svg";
import useWeb3Ctx from "../hooks/useWeb3Ctx";

const BP1 = "@media (max-width: 899px)";
const BP5 = "@media (max-width: 500px)";

const sx = {
  root: {
    width: "100%",
    maxWidth: "1922px",
    height: "80px",
    backgroundColor: "#000000",
    px: "10px",
    [BP1]: {
      display: "none",
    },
    position: "fixed",
    zIndex: "1",
  },
  content: {
    width: "100%",
    maxWidth: "1272px",
    height: "100%",
    mx: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "20px",
    [BP1]: {
      display: "none",
    },
  },
  menuText: {
    color: "background.light",
    cursor: "pointer",
  },
  connectBtn: {
    height: "40px",
    color: "#fff",
    borderColor: "#27DFC7",
    "&:focus": {
      backgroundColor: "#27DFC7",
      color: "#fff",
      outlineColor: "#000",
      opacity: 0.8,
    },
    "&:disabled": {
      backgroundColor: "primary.main",
      cursor: "not-allowed",
      opacity: 0.5,
    },
    "&:hover": {
      color: "#fff",
      backgroundColor: "#27DFC7",
      borderColor: "#27DFC7",
    },
    "&:active": {
      outlineColor: "unset",
    },
  },
  logoContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
  },
  headerRoot: {
    width: "100%",
    zIndex: "1",
    maxWidth: "1272px",
    height: "80px",
    mx: "auto",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
    position: "fixed",
    backgroundColor: "#000",
  },
  fixedMenu: {
    height: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "9px 0",
  },
  collapsedMenu: {
    width: "100%",
    height: "240px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    backgroundColor: "#000",
    gap: "5px 0",
    px: "20px",
    paddingBottom: "25px",
    borderRadius: "0% 0% 15% 15%",
    alignItems: "center",
  },
  mobileContainer: {
    position: "fixed",
    zIndex: "1",
    display: "none",
    [BP1]: {
      display: "block",
      gap: "30px",
    },
  },
  menubtn: {
    pr: "20px",
    zIndex: "1",
    marginLeft: "15px",
    minWidth: "70px",
    height: "70px",
  },
  logo: {
    width: "144px",
    mb: "10px",
  },
  errorBtn: {
    textTransform: "uppercase",
    lineHeight: "1.75",
    height: "40px",
    color: "#fff",
    fontSize: "0.9rem",
    padding: "5px 15px",
    minWidth: "150px",
    backgroundColor: "red",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all .3s",
    textTransform: "unset",
    borderRadius: "2px",
  },
  disconnectBtn: {
    textAlign: "center",
    cursor: "pointer",
    color: "rgba(255,255,255,0.5)",
    transition: "color 0.3s ease-in-out",
    margin: 0,
    marginLeft: "-7px",
    fontSize: "0.8rem",
    lineHeight: "0.3rem",
    marginTop: "-5px",
    "&:hover": {
      opacity: 0.8,
    },
  },
  address: {
    color: "#fff",
    zIndex: 1,
    minWidth: "150px",
    [BP5]: {
      transform: "scale(0.9)",
    },
  },
};

const ConnectWallet = () => {
  const { handleConnect, handleDisconnect, isCorrectNetwork, address } =
    useWeb3Ctx();

  return (
    <>
      {!address && (
        <Button variant="outlined" sx={sx.connectBtn} onClick={handleConnect}>
          Connect Wallet
        </Button>
      )}
      {address && (
        <Box sx={sx.address} onClick={(e) => e.stopPropagation()}>
          <ECAddress address={address} short blockie scale={5} />
          <Box sx={sx.disconnectBtn} onClick={() => handleDisconnect()}>
            disconnect
          </Box>
        </Box>
      )}
      {/* {address && !isCorrectNetwork && (
        <Box sx={sx.errorBtn}>Wrong Network</Box>
      )} */}
    </>
  );
};

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const history = useHistory();

  const handleCollection = () => {
    const { hash } = history.location;
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document
        .getElementById(hash.substr(1))
        .scrollIntoView({ behavior: "smooth" });
    } else {
      history.push("/#about");
    }
  };

  const handleHome = () => {
    const { pathname } = history.location;
    history.push("/");
    if (pathname == "/") {
      window.scrollTo(0, 0);
    }
  };

  const handleWallet = () => {
    history.push("/wallet");
  };

  const handleForge = () => {
    history.push("/forge");
  };

  return (
    <>
      <Box sx={sx.root}>
        <Box sx={sx.content}>
          <Box sx={sx.leftContainer}>
            <Button
              color="light"
              sx={{ fontSize: "16px" }}
              onClick={handleHome}
            >
              HOME
            </Button>
            <Button
              color="light"
              sx={{ fontSize: "16px" }}
              onClick={handleCollection}
            >
              ABOUT
            </Button>
            <Button
              color="light"
              sx={{ fontSize: "16px" }}
              onClick={handleWallet}
            >
              WALLET
            </Button>
            <Button
              color="light"
              sx={{ fontSize: "16px" }}
              onClick={handleForge}
            >
              FORGE
            </Button>
          </Box>
          <Box sx={sx.leftContainer}>
            <SocialButton variant="discord" />
            <SocialButton variant="twitter" />
            <ConnectWallet />
          </Box>
          <Box sx={sx.logoContainer}>
            <Box component="img" sx={sx.logo} src={logo} alt="logo" />
          </Box>
        </Box>
      </Box>
      <Box sx={sx.mobileContainer}>
        <Box
          sx={sx.headerRoot}
          onClick={() => setMenuOpened((state) => !state)}
        >
          <Box sx={sx.fixedMenu}>
            <Button sx={sx.menubtn}>
              <img
                style={{ width: "100%" }}
                src={menuOpened ? collapseIcon : menuIcon}
                alt="Menu icon"
              />
            </Button>
            <Collapse in={menuOpened} timeout="auto" unmountOnExit>
              <Box sx={sx.collapsedMenu}>
                <Button
                  color="light"
                  sx={{ fontSize: "16px" }}
                  onClick={handleHome}
                >
                  HOME
                </Button>
                <Button
                  color="light"
                  sx={{ fontSize: "16px", zIndex: "1" }}
                  onClick={handleCollection}
                >
                  ABOUT
                </Button>
                <Button
                  color="light"
                  sx={{ fontSize: "16px" }}
                  onClick={handleForge}
                >
                  FORGE
                </Button>

                <Button
                  color="light"
                  sx={{ fontSize: "16px" }}
                  onClick={handleWallet}
                >
                  WALLET
                </Button>
                <ConnectWallet />
              </Box>
            </Collapse>
          </Box>
          <Box sx={sx.logoContainer}>
            <img src={logo} style={{ width: "144px" }} alt="logo" />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Header;
