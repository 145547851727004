import { zoomFetchTokenUris } from "../../utils";
import { zoomFetchTokenUrisWithIds } from "../../utils/zoom2";
import { CARDS, PLUTOS, EC, CARDS_POLYGON } from './reducer';
import config from "../../config";
const { DEPLOYED_CHAIN_ID } = config;

export const SET_CARDS = 'SET_CARDS';
export const SET_PLUTO = 'SET_PLUTO';
export const SET_EC = 'SET_EC';
export const SET_LOADING = 'SET_LOADING';
export const SET_CARDS_POLYGON = "SET_CARDS_POLYGON";

export const loadWallet = (contract, zoom2, polygonContract, polygonZoom2, address) => (dispatch, getState) => async () => {
	if (contract && address && zoom2) {
		dispatch({ type: SET_LOADING, name: CARDS })
		const info = await contract.tellEverything()
		const metadata = await zoomFetchTokenUris(contract, zoom2, address)
		if (metadata && metadata.length > 0) {
			metadata.forEach(metadataElem => {
				metadataElem.isOnMainnet = true;
			});
		}
		const polygonMetadata = await zoomFetchTokenUris(polygonContract, polygonZoom2, address);
		const finalMetada = [...(polygonMetadata ? polygonMetadata : []), ...(metadata ? metadata : [])]
		dispatch({
			type: SET_CARDS,
			payload: {
				metadata: finalMetada || [],
				revealed: info._randomReceived
			}
		});
	}
};

export const loadPlutoWallet = (contract, zoom2, address) => (dispatch, getState) => async () => {
	if (contract && address && zoom2) {
		dispatch({ type: SET_LOADING, name: PLUTOS })
		console.log('loading pluto wallet')
		const metadata = await zoomFetchTokenUris(contract, zoom2, address)
		dispatch({
			type: SET_PLUTO,
			payload: metadata || []
		});
	}
}

export const loadEcWallet = (contract, address) => (dispatch, getState) => async () => {
	if (contract && address) {
		dispatch({ type: SET_LOADING, name: EC })
		const balance = await contract.balanceOf(address)
		console.log(Number(balance))
		dispatch({
			type: SET_EC,
			payload: balance > 0
		});
	}
}
// PlutoV2 Forge Contract
export const loadPolygonWallet = (
	forgeContract,
	address,
	plutoV2Contract,
	plutoV2ContractPolygon,
	zoom2ContractPolygon,
	plutoV2ContractMainnet,
	zoom2ContractMainnet
) => (dispatch, getState) => async () => {
	if (plutoV2Contract && forgeContract && address) {
		dispatch({ type: SET_LOADING, name: CARDS_POLYGON })

		// 1: Find out user tokenIds from Mainnet/Goerli
		const balance = await plutoV2Contract.balanceOf(address);
		let tokens = [];
		for (let i = 0; i < balance; i++) {
			tokens.push(await plutoV2Contract.tokenOfOwnerByIndex(address, i))
		}
		const tokenIdsOnMainnet = tokens.map(id => Number(id));


		// 2: Find out user tokenIds from Polygon/Mumbai
		const indexArray = await forgeContract.indexArray(address);
		const tokenIds = indexArray[0].map(id => Number(id));
		const forgeableIds = indexArray[0].filter((id, index) => indexArray[1][index] != 0).map(id => Number(id));


		// 3: Get metadata for all tokenIds on both chains
		let metadata = await zoomFetchTokenUrisWithIds(zoom2ContractPolygon, plutoV2ContractPolygon, tokenIds);
		let metadataOnMainnet = await zoomFetchTokenUrisWithIds(zoom2ContractMainnet, plutoV2ContractMainnet, tokenIdsOnMainnet);
		metadataOnMainnet.forEach(metadata => {
			metadata.isOnMainnet = true;
		});
		const forgeable = metadata.filter(data => forgeableIds.includes(data.id));

		console.log(metadataOnMainnet, " metadataOnMainnet")
		console.log(forgeable, " forgeable")
		// console.log(tokenIds)
		// console.log(forgeableIds)
		// console.log(metadata)
		// console.log(forgeable)
		dispatch({
			type: SET_CARDS_POLYGON,
			payload: {
				metadata: metadata || [],
				forgeable: forgeable || [],
				forgeableOnMainnet: metadataOnMainnet || []
			}
		});
	}
}