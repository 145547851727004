import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import discord from '../assets/images/social/discord.svg';
import twitter from '../assets/images/social/twitter.svg';

const sx = {
	root: {
		width: '40px',
		height: '40px',
		borderRadius: '50%',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#FFFFFF1A',
	},
};

const CONFIG = {
	discord: {
		url: 'https://discord.gg/FZR4eJrFfY',
		image: discord,
	},
	twitter: {
		url: 'https://twitter.com/Pluto_Alliance',
		image: twitter,
	},
};

const SocialButton = ({ variant }) => {
	const { url, image } = CONFIG[variant];
	return (
		<a href={url} target='_blank' rel='noopener noreferrer'>
			<Box sx={sx.root}>
				<img src={image} style={{ width: 24 }} alt={variant} />
			</Box>
		</a>
	);
};

SocialButton.propTypes = {
	variant: PropTypes.oneOf(['discord', 'twitter']).isRequired,
};

export default SocialButton;
