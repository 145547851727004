import { ethers } from "ethers";

const isSale = (time, saleStart, saleEnd) => time >= saleStart && time <= saleEnd;

export const SET_INFO = 'SET_INFO';
export const SET_IS_LOADING = 'SET_IS_LOADING';

export const setInfo = (contract, tokenContract, communityContract, address) => (dispatch, getState) => async () => {
	try {
		dispatch({ type: SET_IS_LOADING, isLoading: true });
		const data = await contract.tellEverything(address);
		const supply = await tokenContract.totalSupply();
		const content = {
			maxSupply: Number(data._maxSupply),
			wallet: data._wallet,
			fullPrice: data._fullPrice,
			discountPrice: data._discountPrice,
			communityPrice: data._communityPrice,
			timedPresale: data._timedPresale,
			presaleStart: Number(data._presaleStart),
			presaleEnd: Number(data._presaleEnd),
			timedSale: data._timedSale,
			saleStart: Number(data._saleStart),
			saleEnd: Number(data._saleEnd),
			dustMintAvailable: data._dustMintAvailable,
			dustPrice: data._dustPrice,
			burnActive: false
		};

		// console.log(data);
		// // console.log('currentSupply', Number(supply))
		// console.log("_maxSupply", Number(data._maxSupply))
		// console.log("_communityMinted", Number(data._communityMinted));
		// console.log("_userMinted", Number(data._userMinted));
		// console.log("_presaleActive", data._presaleActive);
		// console.log("_saleActive", data._saleActive);
		// console.log("_presaleStart", new Date(Number(data._presaleStart) * 1000));
		// console.log("_presaleEnd", new Date(Number(data._presaleEnd) * 1000));
		// console.log("_saleStart", new Date(Number(data._saleStart) * 1000));
		// console.log("_saleEnd", new Date(Number(data._saleEnd) * 1000));

		// console.log("_discountPrice", ethers.utils.commify(Number(ethers.utils.formatEther(data._discountPrice, 18))));
		// console.log("_discountedClaimed", Number(data._discountedClaimed));
		// console.log("_discountedPerAddress", Number(data._discountedPerAddress));

		// console.log("_dustMintActive", data._dustMintActive);
		// console.log("_dustMintAvailable", data._dustMintAvailable);
		// console.log("_dustPrice", Number(data._dustPrice));
		// console.log("_freeClaimed", Number(data._freeClaimed));
		// console.log("_freePerAddress", Number(data._freePerAddress));
		// console.log("_fullPrice", Number(data._fullPrice));
		// console.log("_maxDiscount", Number(data._maxDiscount));
		// console.log("_maxSupply", Number(data._maxSupply));
		// console.log("_maxUserMintable", Number(data._maxUserMintable));
		// console.log("_maxPerSaleMint", Number(data._maxPerSaleMint));
		// console.log("_mintPointer", Number(data._mintPointer));
		// console.log("_timedPresale", data._timedPresale);
		// console.log("_timedSale", data._timedSale);
		// console.log("_totalDiscount", Number(data._totalDiscount));
		// console.log("_totalFreeEC", Number(data._totalFreeEC));

		const time = (new Date()).getTime();
		let {
			presaleStart, presaleEnd, saleStart, saleEnd,
		} = content;
		const { timedPresale, timedSale } = content;

		let status;
		if (timedPresale) {
			presaleStart *= 1000;
			presaleEnd *= 1000;
			if (time < presaleStart) {
				status = 'upcoming_presale';
			}
			if (isSale(time, presaleStart, presaleEnd)) {
				status = 'presale';
			}
		}
		if (!status && timedSale) {
			saleStart *= 1000;
			saleEnd *= 1000;
			if (time < saleStart) {
				status = 'upcoming_sale';
			}
			if (isSale(time, saleStart, saleEnd)) {
				status = 'sale';
			}
		}
		if (!status) {
			status = 'over';
		}


		let now = Date.parse(new Date()) / 1000;
		const saleStarted = Math.floor(Number(data._saleStart)) - now < 0;
		const saleEnded = Math.floor(Number(data._saleEnd)) - now < 0;
		const presaleStarted = Math.floor(Number(data._presaleStart)) - now < 0;
		const presaleEnded = Math.floor(Number(data._presaleEnd)) - now < 0;

		if (!saleStarted) {
			content.burnActive = await communityContract.burn_active();
		}

		const payload = {
			status,
			items: ethers.utils.commify(content.maxSupply),
			sold: ethers.utils.commify(Number(supply)),
			price: ethers.utils.commify(
				Number(ethers.utils.formatEther(content.fullPrice, 18))
			),
			discountPrice: ethers.utils.commify(
				Number(ethers.utils.formatEther(content.discountPrice, 18))
			),
			saleStarted,
			saleEnded,
			presaleStarted,
			presaleEnded,
			burnActive: content.burnActive
		};
		dispatch({
			type: SET_INFO,
			payload,
		});
		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error);
	}
};
