import { createTheme } from '@mui/material';

const COLORS = {
	blue1: '#2699FB',
	blue2: '#00A5F7',
	blue3: '#7FC4FD',
	blue4: '#BCE0FD',
	grey: '#F1F9FF',
	light: '#FFF',
};

const theme = createTheme({
	typography: {
		fontFamily: 'poppins',
		fontSize: '16px',
		color: COLORS.light,
		heading1: {
			fontFamily: 'poppins',
			fontSize: '48px',
			color: COLORS.light,
			fontWeight: 700,
			lineHeight: '68px',
		},
		heading2: {
			fontFamily: 'poppins',
			fontSize: '36px',
			color: COLORS.light,
			fontWeight: 700,
			lineHeight: '48px',
		},
		heading3: {
			fontFamily: 'poppins',
			fontSize: '22px',
			fontWeight: 700,
			lineHeight: '38px',
			color: COLORS.light,
		},
	},
	palette: {
		primary: {
			main: COLORS.light,
		},
		background: {
			socialIcon: COLORS.blue2,
			grey: COLORS.grey,
			infoBox: COLORS.blue3,
			image: COLORS.blue4,
			light: COLORS.light,
		},
		light: {
			main: '#FFF',
		},
		text: {
			primary: COLORS.light,
		},
		error: {
			main: '#EE1B11',
		},
	},
	components: {
		MuiTypography: {
			styleOverrides: {
				body1: {
					color: COLORS.light,
					fontFamily: 'poppins',
					fontSize: '18px',
				},
			},
			defaultProps: {
				variantMapping: {
					body1: 'div',
					heading1: 'div',
					heading2: 'div',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				contained: {
					color: '#FFF',
					height: '48px',
					fontSize: '16px',
					fontWeight: 600,
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: "rgba(255,255,255,0.8)",
					top: "-5px",
					fontStyle: "italic",
					paddingRight: "2px"
				},
				shrink: {
					top: 0
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					zIndex: 0,
					color: "#fff",
					"& .MuiSvgIcon-root": {
						color: "#fff",
					},
					"& .MuiOutlinedInput-input": {
						padding: "10px",
						textAlign: "start",
						fontFamily: "poppins",
						backgroundColor: "#27DFC726",
					},
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: "#27DFC7",
					},
					"&:hover .MuiOutlinedInput-notchedOutline": {
						borderColor: "#27DFC7",
					},
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						borderColor: "#27DFC7",
					},
				}
			}
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					color: "rgba(255,255,255,0.8)",
					fontSize: "12px",
					fontStyle: "normal",
				},
				"&.Mui-error": {
					"color": "#EE1B11"
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					backgroundColor: "#27DFC726",
					color: "#fff",
					"& .MuiSvgIcon-root": {
						color: "#fff",
					},
					"& .MuiOutlinedInput-input": {
						padding: "10px",
						textAlign: "start",
						fontFamily: "poppins"
					},
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: "#27DFC7",
					},
					"&:hover .MuiOutlinedInput-notchedOutline": {
						borderColor: "#27DFC7",
					},
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						borderColor: "#27DFC7",
					},
				}
			}
		},
		MuiMenu: {
			styleOverrides: {
				root: {
					"&& .MuiPopover-paper": {
						backgroundColor: "#0b2c27",
					},
					"&& .Mui-selected": {
						backgroundColor: "#27dfc785",
					},
				}
			}
		}
	},
});

export default theme;
