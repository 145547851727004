export { getContract } from "./contract"
export { zoomFetchTokenUris } from "./zoom2"
import { toast } from "react-toast";
import config from "../config";

export const getTokenUri = async (tokenId, tokenUri) => {
    //console.log(tokenUri)
    // if (config.DEPLOYED_CHAIN_ID == 4) {
    //     tokenUri = tokenUri.replace("pluto-v2-metadata-server.ether.cards", "pluto-v2-metadata-staging.herokuapp.com")
    // }
    const metadata = await fetch(tokenUri)// await fetch(`https://toddlerpillars-metadata-server.herokuapp.com/api/metadata/${tokenId % 100}/${tokenId}`)
        .then((res) => res.json())
        .catch((err) => console.error(err));
    if (metadata) {
        if (!metadata.tokenId) {
            metadata.tokenId = tokenId;
        }
        if (!metadata.id) {
            metadata.id = tokenId;
        }
        return metadata;
    } else
        // Fetching metadata fail, return an object anyway
        return {
            tokenId: tokenId,
            id: tokenId
        }
};

export const handleWalletError = (e) => {
    if (e.error && e.error.message) {
        console.error(e.error.message);
        toast.error(e.error.message);
    } else if (e.message) {
        console.error(e.message);
        toast.error(e.message);
    }
};