// Contact Inputs
export const NAME = "name";
export const EMAIL = "email";
export const PHONE_NUMBER = "phone_number";
export const DISCORD_NAME = "discord_name";
export const CONTACT_PARAMS = [NAME, EMAIL, PHONE_NUMBER, DISCORD_NAME];
// Shipping Inputs
export const ADDRESS = "address";
export const CITY = "city";
export const STATE = "state";
export const ZIP_CODE = "zip_code";
export const SHIPPING_PARAMS = [ADDRESS, CITY, STATE, ZIP_CODE];
// Merch Inputs
export const MERCH_TYPE = "merch_type";
export const TEE_SIZE = "tee_size";
export const HOODIE_SIZE = "hoodie_size";
export const ALL_MERCH_PARAMS = [TEE_SIZE, HOODIE_SIZE];
// Cruise Inputs
export const WALLET_ADDRESS = "wallet_address";
export const CRUISE_TYPE = "cruise_type";
export const REWARD_TYPE = "reward_type"; // Cruise or USDT
export const CRUISE = "cruise";
export const USDT = "usdt";
//
export const REWARD_ID = "reward_id";