import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  DndContext,
  DragOverlay,
  rectIntersection,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { SpinnerDiamond as Spinner } from "spinners-react";
import { Droppable } from "./Droppable";
import { SortableItem } from "./SortableItem";

import Divider from "../../../../../components/Divider";

import placeholder from "../../../../../assets/images/pluto-placeholder_v1.png";
import placeholderV2 from "../../../../../assets/images/plutov2-placeholder.png";

import removeBtn from "../../../../../assets/images/close-icon-white.svg";

const DROPPABLE_ID = "droppable_id";

const BP1 = "@media (max-width: 982px)";
const BP2 = "@media (max-width: 619px)";
const BP3 = "@media (max-width: 552px)";
const BP4 = "@media (max-width: 640px)";
const BP5 = "@media (max-width: 400px)";
const BP6 = "@media (max-width: 340px)";

const sx = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    pb: 2,
  },
  droppableContainer: {
    fontFamily: "poppins",
    width: "80%",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    my: 5,
    [BP4]: {
      width: "90%",
    },
    [BP5]: {
      width: "100%",
    },
  },

  draggableHeader: {
    mt: 5,
    fontSize: 22,
    fontWeight: 700,
    transition: "all .3s",
    color: "#fff",
    textAlign: "center",
    [BP1]: {
      mt: "16px",
    },
  },

  draggableContainer: {
    mt: 5,
    width: "80%",
    // display: "grid",
    // gridTemplateColumns: "repeat(auto-fill, 150px)",
    // gridGap: "1rem",

    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "flex-start",
    alignSelf: "center",
    [BP4]: {
      width: "90%",
    },
    [BP5]: {
      width: "100%",
    },
  },

  droppableItemContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "200px",
    minWidth: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  image: {
    borderRadius: "20px",
  },

  droppableOverlay: {
    position: "absolute",
    borderRadius: "20px",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    background: "rgba(0,0,0,0.3)",
  },

  droppableTokenIdText: {
    fontSize: "0.9rem",
    fontWeight: 700,
    color: "#FFF",
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.5)",
    boxShadow: "0 0 10px 10px rgba(0,0,0,0.5)",
    bottom: "-30px",
    left: 0,
    right: 0,
  },

  droppableWarningText: {
    fontSize: "0.7rem",
    fontWeight: 700,
    color: "#F85353",
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.5)",
    boxShadow: "0 0 10px 10px rgba(0,0,0,0.5)",
    top: "-45px",
    left: 0,
    [BP4]: {
      display: "none",
    },
  },

  droppableWarningTextMobile: {
    display: "none",
    top: "-35px",
    [BP4]: {
      left: "unset",
      mx: "auto",
      display: "unset",
    },
  },
  droppableItemText: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 40,
    color: "#fff",
    fontSize: "14px",
    p: 1,
    [BP2]: {
      bottom: "10%",
    },
    [BP3]: {
      bottom: "20%",
    },
  },

  droppableItemText2: {
    position: "absolute",
    top: 20,
    left: 0,
    right: 0,
    color: "#fff",
    fontSize: "4rem",
    fontWeight: "700",
    p: 1,

    [BP2]: {
      top: 0,
    },
    [BP3]: {
      display: "none",
    },
  },

  itemText: {
    color: "#fff",
    fontSize: "14px",
    mt: 1,
  },

  tokenContainer: {
    backgroundColor: "rgb(0, 0, 0, 0.8)",
    width: "100%",
    paddingTop: "125%",
    position: "relative",
    zIndex: 0,
    borderRadius: "20px",
    overflow: "hidden",

    "& img": {
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },

  equal: {
    color: "#fff",
    fontWeight: "700",
    fontSize: "5rem",
    textTransform: "uppercase",
    textAlign: "center",
    placeSelf: "center",
    mx: 3,
    [BP4]: {
      mx: 0,
    },
    [BP5]: {
      fontSize: "3rem",
    },
  },

  removeBtn: {
    position: "absolute",
    width: "40px !important",
    height: "40px !important",
    margin: "auto",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    cursor: "pointer",
    pointerEvents: "auto",
    transition: "all .3s",
  },

  mintBtn: {
    fontSize: "0.9rem",
    fontWeight: 700,
    minWidth: "150px",
    height: "50px",
    color: "#fff",
    cursor: "pointer",
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    transition: "all .3s",
    textTransform: "unset",
    border: "1px white solid",
    px: 4,
    "&:hover": {
      color: "#000",
      background: "#fff",
      borderColor: "#fff",
    },
    "&:focus": {
      color: "#000",
      background: "#fff",
      outlineColor: "#000",
      opacity: 0.8,
    },
    "&:active": {
      outlineColor: "unset",
    },
    "&:disabled": {
      color: "#fff",
      backgroundColor: "#000",
      cursor: "not-allowed",
      opacity: 0.5,
    },
  },
};

const DragAndDrop = ({ handleMint }) => {
  const { metadata: plutoTokens, isLoading } = useSelector(
    (state) => state.wallet.plutos
  );

  const [items, setItems] = useState({
    draggable: [],
    droppable: null,
  });

  useEffect(() => {
    if (plutoTokens) {
      setItems({
        draggable: plutoTokens.map((token) => {
          token.id = token.tokenId;
          return token;
        }),
        droppable: null,
      });
    } else {
      setItems({ draggable: [], droppable: null });
    }
  }, [plutoTokens]);

  const [activeItem, setActiveItem] = useState(null);

  const getIndex = (tokenId) => {
    const index = items.draggable.map((item) => item.tokenId).indexOf(tokenId);
    return index;
  };
  const reorderItems = arrayMove;

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = ({ active, over }) => {
    console.log("drag end");

    console.log(active);

    setActiveItem(null);

    if (over) {
      if (over.id === DROPPABLE_ID) {
        if (items.droppable) {
          if (items.droppable.id !== active.id) {
            setItems((items) => {
              return {
                droppable: activeItem,
                draggable: [
                  items.droppable,
                  ...items.draggable.filter((item) => item.id !== active.id),
                ],
              };
            });
          }
        } else {
          setItems((items) => {
            return {
              droppable: activeItem,
              draggable: items.draggable.filter(
                (item) => item.id !== active.id
              ),
            };
          });
        }
      } else {
        const overIndex = getIndex(over.id);
        const activeIndex = getIndex(active.id);

        if (activeIndex !== overIndex) {
          // console.log("reorder Items");
          // console.log("activeIndex:", activeIndex);
          // console.log("overIndex:", overIndex);
          setItems((items) => {
            return {
              droppable: items.droppable,
              draggable: reorderItems(items.draggable, activeIndex, overIndex),
            };
          });
        }
      }
    }
  };

  const handleDragStart = ({ active }) => {
    if (!active) {
      return;
    }

    const item = items.draggable.find((item) => item.id == active.id);

    setActiveItem(item);
  };

  const handleDragCancel = () => {
    setActiveItem(null);
  };

  const handleRemoveDroppable = () => {
    setItems({
      draggable: [items.droppable, ...items.draggable],
      droppable: null,
    });
  };

  const defaultAnnouncements = {
    onDragStart(id) {
      console.log(`Picked up draggable item ${id}.`);
    },
    onDragOver(id, overId) {
      if (overId) {
        console.log(
          `Draggable item ${id} was moved over droppable area ${overId}.`
        );
        return;
      }

      console.log(`Draggable item ${id} is no longer over a droppable area.`);
    },
    onDragEnd(id, overId) {
      if (overId) {
        console.log(
          `Draggable item ${id} was dropped over droppable area ${overId}`
        );
        return;
      }

      console.log(`Draggable item ${id} was dropped.`);
    },
    onDragCancel(id) {
      console.log(`Dragging was cancelled. Draggable item ${id} was dropped.`);
    },
  };

  const handleImgError = (e) => {
    console.log("error");
    e.target.onerror = null;
    e.target.src = { placeholder };
  };

  const onClickMint = () => {
    console.log("mint this");
  };

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={rectIntersection}
      //announcements={defaultAnnouncements}
      sensors={sensors}
    >
      <Box sx={sx.wrapper}>
        <Box sx={sx.droppableContainer}>
          <Droppable id={DROPPABLE_ID} style={{ padding: "4px" }}>
            <Box
              sx={{
                ...sx.tokenContainer,
                border: "3px dashed white",
                overflow: "visible",
              }}
            >
              {items.droppable ? (
                <>
                  <Box
                    component="img"
                    src={items.droppable.image || placeholder}
                    sx={sx.image}
                    onError={handleImgError}
                  />
                  <Box sx={sx.droppableOverlay} />
                  <Box
                    component="img"
                    sx={sx.removeBtn}
                    src={removeBtn}
                    onClick={() => handleRemoveDroppable()}
                  />
                </>
              ) : (
                <>
                  <Box sx={sx.droppableItemText2}>+</Box>
                  <Box sx={sx.droppableItemText}>
                    Drag & Drop an eligible NFT from your wallet here
                  </Box>
                </>
              )}

              <Typography variant="text" sx={sx.droppableWarningText}>
                Your Pluto Alliance V1 NFT will get burned during this process!
              </Typography>

              {items.droppable && (
                <Typography variant="text" sx={sx.droppableTokenIdText}>
                  Pluto V1: #{items.droppable.id}
                </Typography>
              )}
            </Box>
          </Droppable>

          <Box sx={sx.equal}>=</Box>

          <Box sx={sx.droppableItemContainer}>
            <Box
              sx={{
                ...sx.tokenContainer,
                boxShadow: "0 0 20px 3px rgba(255,255,255,0.5)",
              }}
            >
              <Box
                component="img"
                src={placeholderV2}
                sx={{ objectFit: "contain" }}
              />
            </Box>
          </Box>

          <Typography
            variant="text"
            sx={{
              ...sx.droppableWarningText,
              ...sx.droppableWarningTextMobile,
            }}
          >
            Your Pluto Alliance V1 NFT will get burned during this process!
          </Typography>
        </Box>

        <Button
          variant="outlined"
          sx={sx.mintBtn}
          onClick={() => handleMint(items.droppable.id)}
          disabled={!items.droppable}
        >
          I DID IT. GIVE ME THE NFT
        </Button>

        <Typography variant="text" sx={sx.draggableHeader}>
          Pluto Alliance V1 In Your Wallet: {items.draggable.length}
        </Typography>

        <Divider titleDivider />

        {isLoading && (
          <Box sx={{ textAlign: "center", mt: 5 }}>
            <Spinner color="#19A8B4" secondaryColor="rgba(255,255,255,0.5)" />
          </Box>
        )}

        <SortableContext items={items.draggable}>
          <Box sx={sx.draggableContainer}>
            {!isLoading &&
              items.draggable.map((value, index) => (
                <SortableItem key={value.id} id={value.id} index={index}>
                  <Box sx={sx.tokenContainer}>
                    <Box
                      component="img"
                      src={value.image || placeholder}
                      onError={handleImgError}
                    ></Box>
                  </Box>
                  <Typography sx={sx.itemText}>Pluto V1: #{value.id}</Typography>
                </SortableItem>
              ))}
          </Box>
        </SortableContext>
      </Box>

      <DragOverlay>
        {activeItem ? (
          <Box sx={sx.tokenContainer} id={activeItem.id}>
            <Box
              component="img"
              src={activeItem.image || placeholder}
              onError={handleImgError}
            ></Box>
          </Box>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

/* eslint-disable react/forbid-prop-types */
DragAndDrop.propTypes = {
  handleMint: PropTypes.any.isRequired,
};

export default DragAndDrop;
