import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { Box } from "@mui/material";

const sx = {
  droppable: {
    width: "100%",
    position: "relative",
    maxWidth: "200px",
    minWidth: "150px",
    p: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
};

export function Droppable({ children, style, id }) {
  const { isOver, setNodeRef } = useDroppable({
    id: id,
  });

  const _style = {
    opacity: isOver ? 0.7 : 1,
    ...style,
  };

  return (
    <Box sx={sx.droppable} ref={setNodeRef} style={_style}>
      {children}
    </Box>
  );
}
